import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { Airport, BedBankHotel, BedBankHotelListQuery, BedBankHotelListQueryResponse } from 'src/app/core/autogenerated/model.autogenerated';
import { TailwindBreakpoint } from 'src/app/core/models/tailwind-breakpoint';
import { ApiService } from 'src/app/core/services/api.service';
import { ViewBreakpointsService } from 'src/app/core/services/view-breakpoints.service';
import { MomentFormatType } from 'src/app/sharedmodules/moment-format/moment-format.pipe';

@Component({
  selector: 'app-bed-bank-hotel-list',
  templateUrl: './bed-bank-hotel-list.component.html',
  styleUrls: ['./bed-bank-hotel-list.component.css']
})
export class BedBankHotelListComponent {
  private _subscription?: Subscription;
  private _langSub?: Subscription;

  /**
   * Pagination
   */
  public PAGESIZE = 10; //50;
  public pageIndex: number = 0;
  public length: number = 0;

  // Filters for the query
  public idAirportFilter?: number;
  public dateFilter?: moment.Moment;
  public hotelFilter?: string;

  public todayMinusOneDate?: moment.Moment;
  public todayDate?: moment.Moment;
  public todayPlusOneDate?: moment.Moment;
  public todayPlusTwoDate?: moment.Moment;
  // Days displayed
  public days?: moment.Moment[] = [];
  // Data
  public airport?: Airport;
  public bedBankHotelList?: BedBankHotel[];
  // Page loading control
  public loading: boolean = true;
  public errata?: boolean;

  public MomentFormatType = MomentFormatType;

  // Table columns
  public displayedColumns = [
    'airport',
    'today-1',
    'today',
    'today+1',
    'today+2'
  ];

  // Tailwind mobile
  public tailwindBreakpoint$: Observable<TailwindBreakpoint | null>;
  public TailwindBreakpointEnum = TailwindBreakpoint;

  constructor(
    private readonly _api: ApiService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _viewBreakpoints: ViewBreakpointsService,
    private readonly _translate: TranslateService
  ) {
    // Obtain the main filter from the route
    this.idAirportFilter = Number(this._activatedRoute.snapshot.params['idAirport']);

    this.tailwindBreakpoint$ = _viewBreakpoints.tailwindBreakpoint$;
    // Refresh of the filter: the dates need to update their format upon language change
    this._langSub = this._translate.onLangChange.subscribe(x => {
      this.calculateDates();
    });
    this.calculateDates();
  }

  ngOnInit(): void {
    this.reload();
  }

  private calculateDates() {
    this.dateFilter = moment();
    this.todayMinusOneDate = moment().subtract(1, 'days');
    this.todayDate = moment();
    this.todayPlusOneDate = moment().add(1, 'days');
    this.todayPlusTwoDate = moment().add(2, 'days');
  }

  public reload() {
    this.loading = true;

    this._subscription?.unsubscribe();

    // Check if I don't have filters
    const filtri = this.createRequest();

    this._subscription = forkJoin({ airport: this._api.Airport_Read(this.idAirportFilter), bedBankHotelList: this._api.BedBank_BedBankHotelList(filtri) })
      .subscribe({
        next: (response: { airport: Airport, bedBankHotelList: BedBankHotelListQueryResponse }) => {
          this.airport = response?.airport;

          if (response?.bedBankHotelList) {
            // Calcolo la paginazione
            this.length = response.bedBankHotelList.items?.length ?? 0;
            this.bedBankHotelList = response.bedBankHotelList?.items?.filter((x: BedBankHotel, index: number) => {
              return (
                index >= this.pageIndex * this.PAGESIZE &&
                index < (this.pageIndex + 1) * this.PAGESIZE
              );
            }) ?? [];
          }
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
        },
      });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
    this._langSub?.unsubscribe();
  }

  public paginazione(paginazione: PageEvent) {
    this.pageIndex = paginazione.pageIndex;
    this.reload();
  }

  createRequest(): BedBankHotelListQuery {
    const queryFilter = new BedBankHotelListQuery();
    queryFilter.idAirport = this.idAirportFilter;
    queryFilter.date = this.dateFilter;
    queryFilter.hotel = this.hotelFilter;
    return queryFilter;
  }

  filterSearch() {
    this.pageIndex = 0;
    this.reload();
  }

  onClearFilter() {
    this.hotelFilter = undefined;
    this.pageIndex = 0;
    this.reload();
  }

  navigateToBedBank() {
    this._router.navigate(['list'], { relativeTo: this._activatedRoute.parent });
  }
}

<div class="camera-button-container">
  <div class="camera-button">
    <button mat-fab color="primary" aria-label="Alt Camera" (click)="nextCamera()">
      <!-- <mat-icon>camera_alt</mat-icon> -->
      <mat-icon>autorenew</mat-icon>
    </button>
  </div>
</div>

<zxing-scanner
  #scanner
  start="true"
  class="!flex w-full h-full"
  previewFitMode="cover"
  [autofocusEnabled]="true"
  [enable]="true"
  [(device)]="currentDevice"
  scannerEnabled="true"
  [tryHarder]="true"
  (scanSuccess)="onScanSuccess($event)"
  (scanComplete)="onScanComplete($event)"
  [formats]="supportedBarcodeFormats">
</zxing-scanner>

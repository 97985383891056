import * as moment from 'moment';


export class ActivatedVoucherSnackListResponse {
  idTicket: number | undefined;
  airlineCompanyName: string | undefined;
  flightNumber: string | undefined;
  ticketOpeningDate: moment.Moment | undefined;
  totalVoucherSnack: number | undefined;
  totalAmount: number | undefined;
  vouchersSnacks: VoucherSnack[] | undefined;
}

export class ActivatedVoucherSnackReadQueryResponse {
  items: ActivatedVoucherSnackListResponse[] | undefined;
}

export class ActivationError {
  voucher: string | undefined;
  seq: string | undefined;
  errorMessage: string | undefined;
  exception: string | undefined;
}

export class AirlineCompany {
  idAirlineCompany: number | undefined;
  idPassengerRegistrationTypeDefault: PassengerRegistrationTypeEnum | undefined;
  idVoucherSnackManagementTypeDefault: VoucherSnackManagementTypeEnum | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
}

export class AirlineCompanyCreateCommand {
  idPassengerRegistrationTypeDefault: PassengerRegistrationTypeEnum | undefined;
  idVoucherSnackManagementTypeDefault: VoucherSnackManagementTypeEnum | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
}

export class AirlineCompanyInAirportCreateCommand {
  idAirlineCompany: number | undefined;
  idAirport: number | undefined;
}

export class AirlineCompanyListAirportsQueryResponse {
  items: AirportInAirlineCompany[] | undefined;
}

export class AirlineCompanyListQueryResponse {
  items: AirlineCompany[] | undefined;
}

export class AirlineCompanyListUsersQueryResponse {
  items: User[] | undefined;
}

export class AirlineCompanyUpdateCommand {
  idAirlineCompany: number | undefined;
  idPassengerRegistrationTypeDefault: PassengerRegistrationTypeEnum | undefined;
  idVoucherSnackManagementTypeDefault: VoucherSnackManagementTypeEnum | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
}

export class Airport {
  idAirport: number | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  cityCode: string | undefined;
  cityName: string | undefined;
  countryName: string | undefined;
  countryCode: string | undefined;
  timezone: string | undefined;
}

export class AirportHasHotel {
  idAirportHasHotel: number | undefined;
  idAirport: number | undefined;
  idHotel: number | undefined;
  rating: number | undefined;
  googleMapDistance: string | undefined;
  googleMapTimeDistance: string | undefined;
  airport: Airport | undefined;
  hotel: Hotel | undefined;
}

export class AirportHasHotelCreateCommand {
  idAirport: number | undefined;
  idHotel: number | undefined;
  rating: number | undefined;
  googleMapDistance: string | undefined;
  googleMapTimeDistance: string | undefined;
}

export class AirportHasHotelDeleteCommand {
  idAirportHasHotel: number | undefined;
}

export class AirportHasHotelListAirportByHotelQueryResponse {
  items: AirportHasHotel[] | undefined;
}

export class AirportHasHotelUpdateCommand {
  idAirportHasHotel: number | undefined;
  idAirport: number | undefined;
  idHotel: number | undefined;
  rating: number | undefined;
  googleMapDistance: string | undefined;
  googleMapTimeDistance: string | undefined;
}

export class AirportInAirlineCompany {
  idAirlineCompanyInAirport: number | undefined;
  idAirport: number | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  cityCode: string | undefined;
  cityName: string | undefined;
  countryName: string | undefined;
  countryCode: string | undefined;
  timezone: string | undefined;
}

export class AirportListQueryResponse {
  items: Airport[] | undefined;
}

export class AirportManaged {
  idAirport: number | undefined;
  name: string | undefined;
  iataCode: string | undefined;
  cityName: string | undefined;
}

export class AirportManagedListQuery {
}

export class AirportManagedListQueryResponse {
  items: AirportManaged[] | undefined;
}

export class ApplicationException {
  message: string | undefined;
  stackTrace: string | undefined;
}

export class Attachment {
  idAttachment: number | undefined;
  idAttachmentType: AttachmentTypeEnum | undefined;
  fileName: string | undefined;
  filePath: string | undefined;
  fileType: string | undefined;
  description: string | undefined;
  uploadDate: moment.Moment | undefined;
}

export class AttachmentType {
  idAttachmentType: AttachmentTypeEnum | undefined;
  name: string | undefined;
}

export enum AttachmentTypeEnum {
  CONTRACT = 1,
  DOCUMENTATION = 2,
  OTHER = 3,
  EMAIL = 4,
}

export class AuthRefreshTokenCommand {
  username: string | undefined;
  refreshToken: string | undefined;
  ipAddress: string | undefined;
}

export class AuthTokenByIdAuthJwtTokenCommand {
  idAuthJwtToken: string | undefined;
  ipAddress: string | undefined;
}

export class AuthTokenCommand {
  username: string | undefined;
  password: string | undefined;
  ipAddress: string | undefined;
}

export class AuthTokenHotacCommand {
  idHotel: number | undefined;
  username: string | undefined;
  date: moment.Moment | undefined;
  ipAddress: string | undefined;
}

export class AuthenticationToken {
  idAuthJwtToken: string | undefined;
  idUser: number | undefined;
  username: string | undefined;
  accessToken: string | undefined;
  expiresIn: number | undefined;
  refreshToken: string | undefined;
  refreshTokenExpiresIn: number | undefined;
}

export class BedBankAirportListQuery {
  date: moment.Moment | undefined;
  airport: string | undefined;
}

export class BedBankAirportListQueryResponse {
  items: BedBankAirportResponse[] | undefined;
}

export class BedBankAirportResponse {
  idAirport: number | undefined;
  airport: string | undefined;
  iataCode: string | undefined;
  bedBankAirportRoomsTodayMinusOne: BedBankAirportRoom | undefined;
  bedBankAirportRoomsToday: BedBankAirportRoom | undefined;
  bedBankAirportRoomsTodayPlusOne: BedBankAirportRoom | undefined;
  bedBankAirportRoomsTodayPlusTwo: BedBankAirportRoom | undefined;
}

export class BedBankAirportRoom {
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  hotels: number | undefined;
}

export class BedBankHotel {
  idHotel: number | undefined;
  name: string | undefined;
  bedBankHotelRoomsTodayMinusOne: BedBankHotelRoom | undefined;
  bedBankHotelRoomsToday: BedBankHotelRoom | undefined;
  bedBankHotelRoomsTodayPlusOne: BedBankHotelRoom | undefined;
  bedBankHotelRoomsTodayPlusTwo: BedBankHotelRoom | undefined;
}

export class BedBankHotelListQuery {
  date: moment.Moment | undefined;
  idAirport: number | undefined;
  hotel: string | undefined;
}

export class BedBankHotelListQueryResponse {
  items: BedBankHotel[] | undefined;
}

export class BedBankHotelRoom {
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
}

export class CommunicationItem {
  idCommunicationItem: number | undefined;
  idCommunicationItemType: CommunicationItemTypeEnum | undefined;
  idCommunicationSubjectType: CommunicationSubjectTypeEnum | undefined;
  inOut: number | undefined;
  date: moment.Moment | undefined;
  notes: string | undefined;
  userName: string | undefined;
}

export class CommunicationItemType {
  idCommunicationItemType: CommunicationItemTypeEnum | undefined;
  name: string | undefined;
}

export enum CommunicationItemTypeEnum {
  NOTSET = 0,
  EMAIL = 1,
  PHONE = 2,
  MEETING = 4,
}

export class CommunicationSubjectType {
  idCommunicationSubjectType: CommunicationSubjectTypeEnum | undefined;
  name: string | undefined;
}

export enum CommunicationSubjectTypeEnum {
  NotSet = 0,
  RateReminder = 1,
  FirstContact = 2,
  ProposalOnRequest = 3,
  RateNegotiation = 4,
  PositiveClosure = 5,
  NegativeClosure = 6,
  InformationRequest = 7,
  Other = 8,
  ReminderOnRequest = 9,
  OnCall = 10,
  OnCallInfo = 11,
}

export class Contact {
  idContact: number | undefined;
  idContactType: ContactTypeEnum | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
}

export class ContactType {
  idContactType: ContactTypeEnum | undefined;
  name: string | undefined;
}

export enum ContactTypeEnum {
  REFERENCE = 1,
  COMMERCIAL = 2,
  ADMINISTRATIVE = 3,
  RECEPTION = 4,
  DUTY_MANAGER = 5,
  CONTACT_CENTER = 6,
  OTHER = 7,
}

export class ContractPlanRateReadQueryResponse {
  idContract: number | undefined;
  idContractPlanRate: number | undefined;
  idContractType: ContractTypeEnum | undefined;
  bestAvailableRateSalePercentage: number | undefined;
  hotelRoomGroups: HotelRoomGroup[] | undefined;
}

export class ContractState {
  idContractState: ContractStateEnum | undefined;
  name: string | undefined;
}

export enum ContractStateEnum {
  ACTIVE = 1,
  INACTIVE = 2,
  EXPIRED = 3,
}

export class ContractType {
  idContractType: ContractTypeEnum | undefined;
  name: string | undefined;
}

export enum ContractTypeEnum {
  NEGOTIATED_RATES = 1,
  ON_REQUEST_RATES = 2,
  MIXED = 3,
}

export class Country {
  idCountry: number | undefined;
  code: string | undefined;
  name: string | undefined;
  search: string | undefined;
}

export class CountryListQueryResponse {
  items: Country[] | undefined;
}

export class EnumListHotelAreaQueryResponse {
  contactTypes: ContactType[] | undefined;
  attachmentTypes: AttachmentType[] | undefined;
  hotelRateTypes: HotelRateType[] | undefined;
  hotelRoomResultTypes: HotelRoomResultType[] | undefined;
  hotelStates: HotelState[] | undefined;
  userStates: UserState[] | undefined;
  userTypes: UserType[] | undefined;
  communicationItemTypes: CommunicationItemType[] | undefined;
  communicationSubjectTypes: CommunicationSubjectType[] | undefined;
}

export class EnumListQueryResponse {
  contactTypes: ContactType[] | undefined;
  contractStates: ContractState[] | undefined;
  contractTypes: ContractType[] | undefined;
  attachmentTypes: AttachmentType[] | undefined;
  hotelRateTypes: HotelRateType[] | undefined;
  hotelRoomExclusiveServices: HotelRoomExclusiveService[] | undefined;
  hotelRoomInformationSources: HotelRoomInformationSource[] | undefined;
  hotelRoomOccupancies: HotelRoomOccupancy[] | undefined;
  hotelRoomResultTypes: HotelRoomResultType[] | undefined;
  hotelRoomTypes: HotelRoomType[] | undefined;
  hotelStates: HotelState[] | undefined;
  passengerGroupRoles: PassengerGroupRole[] | undefined;
  passengerRegistrationTypes: PassengerRegistrationType[] | undefined;
  serviceRequestStates: ServiceRequestState[] | undefined;
  serviceRequestTypes: ServiceRequestType[] | undefined;
  userStates: UserState[] | undefined;
  userTypes: UserType[] | undefined;
  voucherSnackManagementTypeEnum: VoucherSnackManagementType[] | undefined;
}

export class FoodServiceCompany {
  idFoodServiceCompany: number | undefined;
  name: string | undefined;
  restaurantGroup: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
  fileNameLogo: string | undefined;
  disclaimerOrderForm: string | undefined;
}

export class FoodServiceCompanyCreateCommand {
  name: string | undefined;
  restaurantGroup: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
  fileNameLogo: string | undefined;
  disclaimerOrderForm: string | undefined;
}

export class FoodServiceCompanyListQueryResponse {
  items: FoodServiceCompany[] | undefined;
}

export class FoodServiceCompanyListUsersQueryResponse {
  items: User[] | undefined;
}

export class FoodServiceCompanyUpdateCommand {
  idFoodServiceCompany: number | undefined;
  name: string | undefined;
  restaurantGroup: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  notes: string | undefined;
  fileNameLogo: string | undefined;
  disclaimerOrderForm: string | undefined;
}

export class FoodServiceCompanyUserEmitVoucherSnackCommand {
  boardingPass: string | undefined;
}

export class FoodServiceCompanyUserListVoucherSnackByBoardingPassResponse {
  pnr: string | undefined;
  flightNumber: string | undefined;
  airlineCompanyIata: string | undefined;
  dayOfTheYear: number | undefined;
  toEmit: ServiceSnackBoardingPassScanLogItem[] | undefined;
  emitted: ServiceSnackBoardingPassScanLogItem[] | undefined;
}

export class FoodServiceCompanyVoucherSnackBoardingPassScannedListResponse {
  items: ServiceSnackBoardingPassScanLogItem[] | undefined;
}

export class GetHotelsByAirportQueryResponse {
  items: HotelWorkViewModel[] | undefined;
}

export class Hotel {
  idHotel: number | undefined;
  idCountry: number | undefined;
  idHotelState: HotelStateEnum | undefined;
  idCurrency: number | undefined;
  idHotelRate: number | undefined;
  idHotelRateType: HotelRateTypeEnum | undefined;
  idAirportReference: number | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  city: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  webSite: string | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  address: string | undefined;
  notes: string | undefined;
  tariffNotes: string | undefined;
  contactsNotes: string | undefined;
  totalRooms: number | undefined;
  workItemOrder: number | undefined;
  uniqueCode: string | undefined;
  hasRestaurant: boolean | undefined;
  cityTax: number | undefined;
  paymentId: number | undefined;
  sendAlertTypeId: number | undefined;
  channelManager: string | undefined;
  statusId: number | undefined;
  idGruppo: number | undefined;
  hotelCellulare: string | undefined;
  deviceStatusId: number | undefined;
  deviceIdentificativo: string | undefined;
  deviceNumber: string | undefined;
  deviceDataConsegna: moment.Moment | undefined;
  idHotelBakuun: string | undefined;
  idChannelManager: number | undefined;
  starTypeId: number | undefined;
  sendingEmailId: number | undefined;
  hotelRate: HotelRate | undefined;
}

export class HotelAttachment {
  idHotelAttachment: number | undefined;
  idAttachment: number | undefined;
  idHotel: number | undefined;
  attachment: Attachment | undefined;
}

export class HotelAttachmentCreateCommand {
  idHotel: number | undefined;
  idAttachmentType: AttachmentTypeEnum | undefined;
  fileName: string | undefined;
  filePath: string | undefined;
  fileType: string | undefined;
  description: string | undefined;
  uploadDate: moment.Moment | undefined;
}

export class HotelAttachmentDeleteCommand {
  idHotelAttachment: number | undefined;
}

export class HotelAttachmentListQueryResponse {
  items: HotelAttachment[] | undefined;
}

export class HotelAttachmentUpdateCommand {
  idHotelAttachment: number | undefined;
  idHotel: number | undefined;
  idAttachmentType: AttachmentTypeEnum | undefined;
  fileName: string | undefined;
  filePath: string | undefined;
  fileType: string | undefined;
  description: string | undefined;
  uploadDate: moment.Moment | undefined;
}

export class HotelAvailabilityItem {
  idHotel: number | undefined;
  idHotelRoom: number | undefined;
  idHotelRoomResult: number | undefined;
  hotelRoomResultType: HotelRoomResultTypeEnum | undefined;
  aeroporto: string | undefined;
  hotel: string | undefined;
  prefissoHotel: string | undefined;
  telefonoHotel: string | undefined;
  indirizzoHotel: string | undefined;
  cittaHotel: string | undefined;
  capHotel: string | undefined;
  camereTotali: number | undefined;
  haCamere: boolean | undefined;
  data: moment.Moment | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  singoleInCarrello: number | undefined;
  doppieInCarrello: number | undefined;
  tripleInCarrello: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  haPasto: boolean | undefined;
  prezzoPasto: number | undefined;
  note: string | undefined;
  hotelRateType: HotelRateType | undefined;
  totaleCamere: number | undefined;
  totaleCamereInCarrello: number | undefined;
  totalePersone: number | undefined;
  totalePersoneInCarrello: number | undefined;
}

export class HotelBooking {
  idCameraHotelCarrelloPrenotazione: number | undefined;
  idHotel: number | undefined;
  idCameraHotel: number | undefined;
  idCameraHotelEsito: number | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  prezzoPasto: number | undefined;
  prezzoDinnerBox: number | undefined;
}

export class HotelChannelManager {
  idHotelChannelManager: number | undefined;
  name: string | undefined;
  description: string | undefined;
  notes: string | undefined;
}

export class HotelChannelManagerCreateCommand {
  name: string | undefined;
  description: string | undefined;
  notes: string | undefined;
}

export class HotelChannelManagerDeleteCommand {
  idHotelChannelManager: number | undefined;
}

export class HotelChannelManagerListQuery {
}

export class HotelChannelManagerListQueryResponse {
  items: HotelChannelManager[] | undefined;
}

export class HotelChannelManagerUpdateCommand {
  idHotelChannelManager: number | undefined;
  name: string | undefined;
  description: string | undefined;
  notes: string | undefined;
}

export class HotelCommunication {
  idHotelCommunication: number | undefined;
  idCommunicationItem: number | undefined;
  idHotel: number | undefined;
  communicationItem: CommunicationItem | undefined;
}

export class HotelCommunicationCreateCommand {
  idHotel: number | undefined;
  idCommunicationItemType: CommunicationItemTypeEnum | undefined;
  idCommunicationSubjectType: CommunicationSubjectTypeEnum | undefined;
  inOut: number | undefined;
  date: moment.Moment | undefined;
  notes: string | undefined;
  userName: string | undefined;
}

export class HotelCommunicationDeleteCommand {
  idHotelCommunication: number | undefined;
}

export class HotelCommunicationListQueryResponse {
  items: HotelCommunication[] | undefined;
}

export class HotelCommunicationUpdateCommand {
  idHotelCommunication: number | undefined;
  idHotel: number | undefined;
  idCommunicationItemType: CommunicationItemTypeEnum | undefined;
  idCommunicationSubjectType: CommunicationSubjectTypeEnum | undefined;
  inOut: number | undefined;
  date: moment.Moment | undefined;
  notes: string | undefined;
  userName: string | undefined;
}

export class HotelContact {
  idHotelContact: number | undefined;
  contactId: number | undefined;
  contact: Contact | undefined;
  idHotel: number | undefined;
}

export class HotelContactCreateCommand {
  idHotel: number | undefined;
  idContactType: ContactTypeEnum | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
}

export class HotelContactDeleteCommand {
  idHotelContact: number | undefined;
}

export class HotelContactListQueryResponse {
  items: HotelContact[] | undefined;
}

export class HotelContactUpdateCommand {
  idHotelContact: number | undefined;
  idHotel: number | undefined;
  idContactType: ContactTypeEnum | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
}

export class HotelGroup {
  idHotelGroup: number | undefined;
  name: string | undefined;
  description: string | undefined;
}

export class HotelGroupCreateCommand {
  name: string | undefined;
  description: string | undefined;
}

export class HotelGroupDeleteCommand {
  idHotelGroup: number | undefined;
}

export class HotelGroupListQuery {
}

export class HotelGroupListQueryResponse {
  items: HotelGroup[] | undefined;
}

export class HotelGroupUpdateCommand {
  idHotelGroup: number | undefined;
  name: string | undefined;
  description: string | undefined;
}

export class HotelRate {
  idHotelRate: number | undefined;
  numberOfMeals: number | undefined;
  numeroDinnerBox: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  quadrupleRooms: number | undefined;
  singleRate: number | undefined;
  doubleRate: number | undefined;
  dusRate: number | undefined;
  tripleRate: number | undefined;
  quadrupleRate: number | undefined;
  mealConfirmed: boolean | undefined;
  mealRate: number | undefined;
  dinnerBoxRate: number | undefined;
  cityTaxRate: number | undefined;
}

export class HotelRateType {
  idHotelRateType: HotelRateTypeEnum | undefined;
  name: string | undefined;
}

export enum HotelRateTypeEnum {
  NOT_NEGOTIATED = 0,
  NEGOTIATED = 1,
  ON_REQUEST = 2,
}

export class HotelRoom {
  idHotelRoom: number | undefined;
  idHotelRoomOccupancy: HotelRoomOccupancyEnum | undefined;
  idHotelRoomExclusiveService: HotelRoomExclusiveServiceEnum | undefined;
  hotelRoomPlanRate: HotelRoomPlanRate | undefined;
  hotelRoomDailyAvailability: HotelRoomDailyAvailability | undefined;
}

export class HotelRoomCartBooking {
  idHotelRoomCartBooking: number | undefined;
  idHotel: number | undefined;
  idCameraHotel: number | undefined;
  idCameraHotelEsito: number | undefined;
  idServiceRequestAirpotToHotel: number | undefined;
  nomeHotel: string | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  singoleInCarrello: number | undefined;
  doppieInCarrello: number | undefined;
  tripleInCarrello: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  prezzoPasto: number | undefined;
  prezzoDinnerbox: number | undefined;
  note: string | undefined;
  isEsitoObsoleto: boolean | undefined;
  nomeUtentePrenotazione: string | undefined;
  dataPrenotazione: moment.Moment | undefined;
  tooltip: string | undefined;
  totaleCamere: number | undefined;
  totaleCamereInCarrello: number | undefined;
  totalePersone: number | undefined;
  totalePersoneInCarrello: number | undefined;
  costo: number | undefined;
}

export class HotelRoomCartBookingBookHotelsCommand {
  idRichiestaServizioAeroportoToHotel: number | undefined;
  prenotazioni: HotelBooking[] | undefined;
}

export class HotelRoomCartBookingListByIdServiceRequestAirportToHotelQueryResponse {
  items: HotelRoomCartBooking[] | undefined;
}

export class HotelRoomCartBookingSaveCommand {
  idHotelRoomCartBooking: number | undefined;
  idCameraHotel: number | undefined;
  idCameraHotelEsito: number | undefined;
  idRichiestaServizioAeroportoToHotel: number | undefined;
  giorno: moment.Moment | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  prezzoPasto: number | undefined;
  prezzoDinnerbox: number | undefined;
  note: string | undefined;
}

export class HotelRoomDailyAvailability {
  idHotelRoomDailyAvailability: number | undefined;
  idHotelRoom: number | undefined;
  date: moment.Moment | undefined;
  availability: number | undefined;
  idUserCreatedBy: number | undefined;
  createdAt: moment.Moment | undefined;
  idUserUpdatedBy: number | undefined;
  updatedAt: moment.Moment | undefined;
}

export class HotelRoomDailyAvailabilitySaveCommand {
  idContract: number | undefined;
  hotelRoomDailyAvailabilities: HotelRoomDailyAvailabilitySaveRequest[] | undefined;
}

export class HotelRoomDailyAvailabilitySaveRequest {
  idHotelRoomDailyAvailability: number | undefined;
  idHotelRoom: number | undefined;
  availability: number | undefined;
}

export class HotelRoomExclusiveService {
  idHotelRoomExclusiveService: HotelRoomExclusiveServiceEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomExclusiveServiceEnum {
  ROOM_ONLY = 1,
  DAY_USE = 2,
  BED_AND_BREAKFAST = 3,
}

export class HotelRoomGroup {
  idHotelRoomGroup: number | undefined;
  idHotelRoomType: HotelRoomTypeEnum | undefined;
  hotelRooms: HotelRoom[] | undefined;
}

export class HotelRoomInformationSource {
  idHotelRoomInformationSource: HotelRoomInformationSourceEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomInformationSourceEnum {
  HOTEL = 1,
  BAKUUN = 2,
  VERTICAL_BOOKING = 3,
}

export class HotelRoomOccupancy {
  idHotelRoomOccupancy: HotelRoomOccupancyEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomOccupancyEnum {
  SINGLE = 1,
  DOUBLE_SINGLE_USE = 2,
  DOUBLE = 3,
  TRIPLE = 4,
  QUADRUPLE = 5,
}

export class HotelRoomPlanRate {
  idHotelRoomPlanRate: number | undefined;
  amount: number | undefined;
}

export class HotelRoomResultDetails {
  idHotel: number | undefined;
  idHotelRoom: number | undefined;
  idHotelRoomResult: number | undefined;
  idTipoCameraHotelEsito: HotelRoomResultTypeEnum | undefined;
  nomeHotel: string | undefined;
  prefissoHotel: string | undefined;
  telefonoHotel: string | undefined;
  indirizzoHotel: string | undefined;
  cittaHotel: string | undefined;
  capHotel: string | undefined;
  giorno: moment.Moment | undefined;
  singole: number | undefined;
  doppie: number | undefined;
  triple: number | undefined;
  singoleInCarrello: number | undefined;
  doppieInCarrello: number | undefined;
  tripleInCarrello: number | undefined;
  tariffaSingola: number | undefined;
  tariffaDoppia: number | undefined;
  tariffaTripla: number | undefined;
  pastoConfirmed: boolean | undefined;
  tariffaPasto: number | undefined;
  tariffaDinnerBox: number | undefined;
  note: string | undefined;
}

export class HotelRoomResultSaveCommand {
  idHotel: number | undefined;
  idHotelRoom: number | undefined;
  idHotelRoomResult: number | undefined;
  idHotelRoomResultType: HotelRoomResultTypeEnum | undefined;
  data: moment.Moment | undefined;
  note: string | undefined;
  singole: number | undefined;
  tariffaSingola: number | undefined;
  doppie: number | undefined;
  tariffaDoppia: number | undefined;
  triple: number | undefined;
  tariffaTripla: number | undefined;
}

export class HotelRoomResultType {
  idHotelRoomResultType: HotelRoomResultTypeEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomResultTypeEnum {
  AVAILABLE = 1,
  NOT_AVAILABLE = 2,
  TO_RECONTACT = 3,
}

export class HotelRoomType {
  idHotelRoomType: HotelRoomTypeEnum | undefined;
  name: string | undefined;
}

export enum HotelRoomTypeEnum {
  STANDARD = 1,
  SUPERIOR = 2,
}

export class HotelState {
  idHotelState: HotelStateEnum | undefined;
  name: string | undefined;
}

export enum HotelStateEnum {
  ACTIVE = 1,
  BLOCKED = 2,
  CANCELED = 3,
}

export class HotelSurveyItem {
  idHotel: number | undefined;
  idHotelRoom: number | undefined;
  idHotelRate: number | undefined;
  hotelName: string | undefined;
  hotelPrefix: string | undefined;
  hotelPhone: string | undefined;
  hotelAddress: string | undefined;
  hotelCity: string | undefined;
  hotelZipCode: string | undefined;
  totalRooms: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  availabilityDate: moment.Moment | undefined;
  isFull: boolean | undefined;
  idSurveyType: number | undefined;
  updatedAt: moment.Moment | undefined;
  idUserUpdatedBy: number | undefined;
  hotelRateType: HotelRateType | undefined;
  roomsTotal: number | undefined;
}

export class HotelSurveyListQuery {
  hotelName: string | undefined;
  cityName: string | undefined;
  date: moment.Moment | undefined;
  idUser: number | undefined;
  idRateType: HotelRateTypeEnum | undefined;
  idSurveyType: number | undefined;
  lastUpdate: moment.Moment | undefined;
}

export class HotelSurveyListQueryResponse {
  hotelSurveyItems: HotelSurveyItem[] | undefined;
}

export class HotelSurveyReadResponse {
  hotelName: string | undefined;
  idHotelRoom: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  lastUpdatedBy: string | undefined;
  lastUpdatedAt: moment.Moment | undefined;
  isFull: boolean | undefined;
}

export class HotelSurveySaveCommand {
  idHotel: number | undefined;
  idHotelRoom: number | undefined;
  date: moment.Moment | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  isFull: boolean | undefined;
}

export class HotelWorkViewModel {
  idHotel: number | undefined;
  name: string | undefined;
  city: string | undefined;
  email: string | undefined;
  totalRooms: number | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  hasRestaurant: boolean | undefined;
  statusId: number | undefined;
  starTypeId: number | undefined;
  singleRate: number | undefined;
  dusRate: number | undefined;
  doubleRate: number | undefined;
  tripleRate: number | undefined;
  cityTaxRate: number | undefined;
  idHotelState: HotelStateEnum | undefined;
  idHotelRateType: HotelRateTypeEnum | undefined;
  address: string | undefined;
  workItemOrder: number | undefined;
  latitude: number | undefined;
  longitude: number | undefined;
  googleMapDistance: string | undefined;
  googleMapTimeDistance: string | undefined;
  idAirportHasHotel: number | undefined;
  workItemsHotel: WorkItemsHotel[] | undefined;
  hotelAttachments: HotelAttachment[] | undefined;
}

export class LanguageCulture {
  idLanguageCulture: number | undefined;
  languageCultureName: string | undefined;
  countryName: string | undefined;
}

export class LanguageCultureListQueryResponse {
  items: LanguageCulture[] | undefined;
}

export class ListServiceRequestResponse {
  items: ServiceRequestResponse[] | undefined;
}

export class PassengerGroupListByIdServiceRequestAirportToAirportResponse {
  items: PassengerGroupResponse[] | undefined;
}

export class PassengerGroupListByIdServiceRequestAirportToHotelResponse {
  items: PassengerGroupResponse[] | undefined;
}

export class PassengerGroupListByIdServiceRequestHotelToAirportResponse {
  items: PassengerGroupResponse[] | undefined;
}

export class PassengerGroupListResponse {
  items: PassengerGroupResponse[] | undefined;
}

export class PassengerGroupNameFinalizeRequest {
  idPassengerGroupName: number | undefined;
  idPassengerGroup: number | undefined;
  surname: string | undefined;
  name: string | undefined;
  idPassengerGroupRole: PassengerGroupRoleEnum | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
}

export class PassengerGroupNameResponse {
  idPassengerGroupName: number | undefined;
  idPassengerGroup: number | undefined;
  idPassengerGroupRole: PassengerGroupRoleEnum | undefined;
  idAirport: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  departureDate: moment.Moment | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
}

export class PassengerGroupNameSaveRequest {
  idPassengerGroupName: number | undefined;
  idPassengerGroup: number | undefined;
  surname: string | undefined;
  name: string | undefined;
  idPassengerGroupRole: PassengerGroupRoleEnum | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
}

export class PassengerGroupResponse {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  idUser: number | undefined;
  email: string | undefined;
  needTransport: boolean | undefined;
  passengersCount: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  quadrupleRooms: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  isRegistered: boolean | undefined;
  creationDate: moment.Moment | undefined;
  hotelName: string | undefined;
  passengersGroupNames: PassengerGroupNameResponse[] | undefined;
}

export class PassengerGroupRole {
  idPassengerGroupRole: PassengerGroupRoleEnum | undefined;
  name: string | undefined;
}

export enum PassengerGroupRoleEnum {
  NORMAL = 1,
  GROUP_LEADER = 2,
}

export class PassengerGroupSaveCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  email: string | undefined;
  confirmCode: string | undefined;
  confirmedDate: moment.Moment | undefined;
  needTransport: boolean | undefined;
  name: string | undefined;
  surname: string | undefined;
  idLanguageCulture: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  passengersGroupNames: PassengerGroupNameSaveRequest[] | undefined;
}

export class PassengerRegistrationActivateCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  activationCode: string | undefined;
}

export class PassengerRegistrationFinalizeCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  idUser: number | undefined;
  email: string | undefined;
  name: string | undefined;
  surname: string | undefined;
  needTransport: boolean | undefined;
  passengersCount: number | undefined;
  singleRooms: number | undefined;
  doubleRooms: number | undefined;
  tripleRooms: number | undefined;
  quadrupleRooms: number | undefined;
  isRegistered: boolean | undefined;
  passengersGroupNames: PassengerGroupNameFinalizeRequest[] | undefined;
}

export class PassengerRegistrationGenerateCodeCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  idLanguageCulture: number | undefined;
  email: string | undefined;
  path: string | undefined;
}

export class PassengerRegistrationTokenCommand {
  idPassengerGroup: number | undefined;
  idTicket: number | undefined;
  activationCode: string | undefined;
  ipAddress: string | undefined;
}

export class PassengerRegistrationType {
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  name: string | undefined;
}

export enum PassengerRegistrationTypeEnum {
  WITH_REGISTRATION = 1,
  WITHOUT_REGISTRATION = 2,
}

export class ReprotectionListQuery {
  idServiceRequestAirportToHotel: number | undefined;
  filter: string | undefined;
  idHotelRateType: HotelRateTypeEnum | undefined;
  date: moment.Moment | undefined;
  idAirport: number | undefined;
  reprotectionListType: ReprotectionListType | undefined;
}

export class ReprotectionListQueryResponse {
  items: HotelAvailabilityItem[] | undefined;
}

export enum ReprotectionListType {
  TO_CONTACT = 1,
  AVAILABLE = 2,
  NOT_AVAILABLE = 3,
  BOOKABLE = 4,
}

export class Result {
  isSuccess: boolean | undefined;
  message: string | undefined;
  stacktrace: string | undefined;
  exception: ApplicationException | undefined;
}

export class ServiceRequestAirportToAirportCreateCommand {
  idTicket: number | undefined;
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirportDeparture: number | undefined;
  idAirportDestination: number | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  reducedMobilityPassengers: boolean | undefined;
  notes: string | undefined;
}

export class ServiceRequestAirportToAirportListResponse {
  items: ServiceRequestAirportToAirportResponse[] | undefined;
}

export class ServiceRequestAirportToAirportResponse {
  idServiceRequestAirportToAirport: number | undefined;
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  creationDate: moment.Moment | undefined;
  errorMessage: string | undefined;
  notes: string | undefined;
  airportDeparture: Airport | undefined;
  airportArrival: Airport | undefined;
  passengersGroups: number[] | undefined;
}

export class ServiceRequestAirportToAirportTransportOrderItemListResponse {
  items: ServiceRequestAirportToAirportTransportOrderItemResponse[] | undefined;
}

export class ServiceRequestAirportToAirportTransportOrderItemResponse {
  idTransportOrder: number | undefined;
  idTransportOrderItem: number | undefined;
  driverName: string | undefined;
  driverPrefix: string | undefined;
  driverPhoneNumber: string | undefined;
  arrivalRequestedPickupDate: moment.Moment | undefined;
  capacity: number | undefined;
}

export class ServiceRequestAirportToAirportUpdateCommand {
  idServiceRequestAirportToAirport: number | undefined;
  passengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirportDeparture: number | undefined;
  idAirportDestination: number | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  passengersGroups: number[] | undefined;
  reducedMobilityPassengers: boolean | undefined;
  notes: string | undefined;
}

export class ServiceRequestAirportToHotelCreateCommand {
  idTicket: number | undefined;
  passengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirportDeparture: number | undefined;
  idAirportOvernight: number | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  idPassengerGroups: number[] | undefined;
  reducedMobilityPassengers: boolean | undefined;
  notes: string | undefined;
}

export class ServiceRequestAirportToHotelListResponse {
  items: ServiceRequestAirportToHotelReadResponse[] | undefined;
}

export class ServiceRequestAirportToHotelReadResponse {
  idServiceRequestAirportToHotel: number | undefined;
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idAirportDeparture: number | undefined;
  idAirportOvernight: number | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  reducedMobilityPassengers: boolean | undefined;
  creationDate: moment.Moment | undefined;
  notes: string | undefined;
  airportDeparture: Airport | undefined;
  airportOvernight: Airport | undefined;
  passengersGroups: number[] | undefined;
}

export class ServiceRequestAirportToHotelTransportOrderItemQueryResponse {
  items: ServiceRequestTransportOrderResponse[] | undefined;
}

export class ServiceRequestAirportToHotelUpdateCommand {
  idServiceRequestAirportToHotel: number | undefined;
  passengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirportDeparture: number | undefined;
  idAirportOvernight: number | undefined;
  requestedPickupDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  idPassengerGroups: number[] | undefined;
  reducedMobilityPassengers: boolean | undefined;
  notes: string | undefined;
}

export class ServiceRequestHotelToAirportCreateCommand {
  idTicket: number | undefined;
  passengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirportDestination: number | undefined;
  replacementFlightDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  reducedMobilityPassengers: boolean | undefined;
  passengersGroups: number[] | undefined;
  notes: string | undefined;
}

export class ServiceRequestHotelToAirportListResponse {
  items: ServiceRequestHotelToAirportReadResponse[] | undefined;
}

export class ServiceRequestHotelToAirportReadResponse {
  idServiceRequestHotelToAirport: number | undefined;
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idAirportDestination: number | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  replacementFlightDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  reducedMobilityPassenger: boolean | undefined;
  creationDate: moment.Moment | undefined;
  airportDestination: Airport | undefined;
  passengersGroups: number[] | undefined;
}

export class ServiceRequestHotelToAirportTransportOrderItemQueryResponse {
  items: ServiceRequestTransportOrderResponse[] | undefined;
}

export class ServiceRequestHotelToAirportUpdateCommand {
  idServiceRequestHotelToAirport: number | undefined;
  idTicket: number | undefined;
  passengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirportDestination: number | undefined;
  replacementFlightDate: moment.Moment | undefined;
  passengersCount: number | undefined;
  reducedMobilityPassengers: boolean | undefined;
  passengersGroups: number[] | undefined;
  notes: string | undefined;
}

export class ServiceRequestResponse {
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idServiceRequestType: ServiceRequestTypeEnum | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  idCreationUser: number | undefined;
  errorMessage: string | undefined;
  notes: string | undefined;
  creationUser: string | undefined;
  serviceRequestType: ServiceRequestType | undefined;
  serviceRequestState: ServiceRequestState | undefined;
}

export class ServiceRequestSnackCreateCommand {
  idAirlineCompany: number | undefined;
  idTicket: number | undefined;
  passengersCount: number | undefined;
  idSnackType: number | undefined;
}

export class ServiceRequestSnackListQueryResponse {
  items: ServiceRequestSnackResponse[] | undefined;
}

export class ServiceRequestSnackResponse {
  idServiceRequestSnack: number | undefined;
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  passengersCount: number | undefined;
  numberOfVouchersEmitted: number | undefined;
  createdAt: moment.Moment | undefined;
  canceledAt: moment.Moment | undefined;
  snackType: SnackType | undefined;
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  errorMessage: string | undefined;
}

export class ServiceRequestSnackUpdateCommand {
  idServiceRequestSnack: number | undefined;
  idSnackType: number | undefined;
  passengersCount: number | undefined;
}

export class ServiceRequestSnackVoucherSnackActivationListQueryResponse {
  items: VoucherSnackActivationSummary[] | undefined;
}

export class ServiceRequestState {
  idServiceRequestState: ServiceRequestStateEnum | undefined;
  name: string | undefined;
}

export enum ServiceRequestStateEnum {
  TO_BE_PROCESSED = 1,
  PROCESSING = 2,
  IN_PROGRESS = 3,
  COMPLETED = 4,
  ERROR = 5,
}

export class ServiceRequestTransportOrderItemResponse {
  idTransportOrder: number | undefined;
  idTransportOrderItem: number | undefined;
  driverName: string | undefined;
  driverPrefix: string | undefined;
  driverPhoneNumber: string | undefined;
  arrivalRequestedPickupDate: moment.Moment | undefined;
  capacity: number | undefined;
}

export class ServiceRequestTransportOrderResponse {
  hotelName: string | undefined;
  passengersCount: number | undefined;
  hotelPrefix: string | undefined;
  hotelPhoneNumber: string | undefined;
  hotelAddress: string | undefined;
  items: ServiceRequestTransportOrderItemResponse[] | undefined;
}

export class ServiceRequestType {
  idServiceRequestType: ServiceRequestTypeEnum | undefined;
  name: string | undefined;
}

export enum ServiceRequestTypeEnum {
  AIRPORT_TO_AIRPORT = 1,
  AIRPORT_TO_HOTEL = 2,
  HOTEL_TO_AIRPORT = 3,
  SNACK = 4,
}

export class ServiceRequestUpdateCommand {
  idTicket: number | undefined;
  idServiceRequest: number | undefined;
  notes: string | undefined;
}

export class ServiceSnackBoardingPassScanLogItem {
  pnr: string | undefined;
  flightNumber: string | undefined;
  idTicket: number | undefined;
  idServiceSnack: number | undefined;
  snackType: SnackType | undefined;
  scannedAt: moment.Moment | undefined;
}

export class SnackType {
  idSnackType: number | undefined;
  name: string | undefined;
  amount: number | undefined;
  backgroundColor: string | undefined;
  textColor: string | undefined;
  colorName: string | undefined;
}

export class SnackTypeListResponse {
  items: SnackType[] | undefined;
}

export class StopoverAvailability {
  hotelsCount: number | undefined;
  availableRooms: number | undefined;
}

export class Ticket {
  idTicket: number | undefined;
  idAirlineCompany: number | undefined;
  idAirportDeparture: number | undefined;
  iataCodeAirportDeparture: string | undefined;
  idAirportDestination: number | undefined;
  iataCodeAirportDestination: string | undefined;
  idAirportOpeningTicket: number | undefined;
  iataCodeAirportOpeningTicket: string | undefined;
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idVoucherSnackManagementType: VoucherSnackManagementTypeEnum | undefined;
  flightNumber: string | undefined;
  passengersCount: number | undefined;
  openingDate: moment.Moment | undefined;
  closingDate: moment.Moment | undefined;
  notes: string | undefined;
  isManualManagement: boolean | undefined;
  idCreationUser: number | undefined;
  registredPassengersCount: number | undefined;
  username: string | undefined;
  idUserType: UserTypeEnum | undefined;
  rowNumber: number | undefined;
  workedRowNumber: number | undefined;
  processingStatus: boolean | undefined;
  hasServiceAirportToAirport: boolean | undefined;
  hasServiceAirportToHotel: boolean | undefined;
  hasServiceHotelToAirport: boolean | undefined;
  hasServiceSnack: boolean | undefined;
  airportDeparture: Airport | undefined;
  airportDestination: Airport | undefined;
  airlineCompany: AirlineCompany | undefined;
}

export class TicketCreateCommand {
  idAirportOpeningTicket: number | undefined;
  idAirportDeparture: number | undefined;
  idAirportDestination: number | undefined;
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirlineCompany: number | undefined;
  isManualManagement: boolean | undefined;
  flightNumber: string | undefined;
  passengersCount: number | undefined;
  notes: string | undefined;
}

export class TicketFile {
  idTicketFile: number | undefined;
  idTicket: number | undefined;
  fileName: string | undefined;
  contentType: string | undefined;
  fileUrl: string | undefined;
}

export class TicketFileListByIdTicketQueryResponse {
  items: TicketFile[] | undefined;
}

export class TicketListQuery {
  flightNumber: string | undefined;
  idAirlineCompany: number | undefined;
  idAirportDeparture: number | undefined;
  idAirportDestination: number | undefined;
  openedFrom: moment.Moment | undefined;
  openedTo: moment.Moment | undefined;
}

export class TicketListQueryResponse {
  items: Ticket[] | undefined;
}

export class TicketUpdateCommand {
  idTicket: number | undefined;
  idAirportOpeningTicket: number | undefined;
  idAirportDeparture: number | undefined;
  idAirportDestination: number | undefined;
  idPassengerRegistrationType: PassengerRegistrationTypeEnum | undefined;
  idAirlineCompany: number | undefined;
  isManualManagement: boolean | undefined;
  flightNumber: string | undefined;
  passengersCount: number | undefined;
  notes: string | undefined;
}

export class TicketUpdateNoteCommand {
  idTicket: number | undefined;
  notes: string | undefined;
}

export class TransportCompany {
  idTransportCompany: number | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  owner: string | undefined;
  notes: string | undefined;
}

export class TransportCompanyCreateCommand {
  idLanguageCulture: number | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  owner: string | undefined;
  notes: string | undefined;
}

export class TransportCompanyListQueryResponse {
  items: TransportCompany[] | undefined;
}

export class TransportCompanyListUsersQueryResponse {
  items: User[] | undefined;
}

export class TransportCompanyUpdateCommand {
  idTransportCompany: number | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  owner: string | undefined;
  notes: string | undefined;
}

export class TransportOrderItemCreateCommand {
  idTransportOrder: number | undefined;
  amount: number | undefined;
  idCurrency: number | undefined;
  driverName: string | undefined;
  driverPrefix: string | undefined;
  driverPhoneNumber: string | undefined;
  capacity: number | undefined;
  arrivalRequestedPickupDate: moment.Moment | undefined;
}

export class TransportOrderItemResponse {
  idTransportOrderItem: number | undefined;
  idTransportCompany: number | undefined;
  idTransportOrder: number | undefined;
  idCurrency: number | undefined;
  amount: number | undefined;
  driverName: string | undefined;
  driverPrefix: string | undefined;
  driverPhoneNumber: string | undefined;
  hotelAddress: string | undefined;
  hotelPrefix: string | undefined;
  hotelPhoneNumber: string | undefined;
  arrivalRequestedPickupDate: moment.Moment | undefined;
  capacity: number | undefined;
}

export class TransportOrderItemUpdateCommand {
  idTransportOrderItem: number | undefined;
  amount: number | undefined;
  driverName: string | undefined;
  driverPrefix: string | undefined;
  driverPhoneNumber: string | undefined;
  capacity: number | undefined;
  arrivalRequestedPickupDate: moment.Moment | undefined;
}

export class TransportOrderListByServiceRequestQueryResponse {
  items: TransportOrderResponse[] | undefined;
}

export class TransportOrderListQueryResponse {
  items: TransportOrderResponse[] | undefined;
}

export class TransportOrderResponse {
  idServiceRequest: number | undefined;
  idTicket: number | undefined;
  idTransportOrder: number | undefined;
  departure: string | undefined;
  destination: string | undefined;
  flightNumber: string | undefined;
  passengersCount: number | undefined;
  notes: string | undefined;
  requestedPickupDate: moment.Moment | undefined;
  replacementFlightDate: moment.Moment | undefined;
  hasTransportArrivalDate: boolean | undefined;
  hasDriverPhoneNumeber: boolean | undefined;
  transportOrderItems: TransportOrderItemResponse[] | undefined;
}

export class TransportOrderUpdateCommand {
  idTicket: number | undefined;
  idServiceRequest: number | undefined;
  notes: string | undefined;
}

export class User {
  idUser: number | undefined;
  idUserState: UserStateEnum | undefined;
  idUserType: UserTypeEnum | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  username: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  userRole: string | undefined;
  lockedDate: moment.Moment | undefined;
  activationDate: moment.Moment | undefined;
}

export class UserCreateCommand {
  idUserState: UserStateEnum | undefined;
  idUserType: UserTypeEnum | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  username: string | undefined;
  password: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  userRole: string | undefined;
  idAirlineCompany: number | undefined;
  idTransportCompany: number | undefined;
  idFoodServiceCompany: number | undefined;
  idAirport: number | undefined;
  idHotel: number | undefined;
  idLayoverOperator: number | undefined;
}

export class UserGenerateResetPasswordCodeCommand {
  usernameOrEmail: string | undefined;
  scheme: string | undefined;
  host: string | undefined;
}

export class UserListInAirportQueryResponse {
  items: User[] | undefined;
}

export class UserListNotInAirportQueryResponse {
  items: User[] | undefined;
}

export class UserListQuery {
  idUserType: UserTypeEnum | undefined;
}

export class UserListQueryResponse {
  items: UserReadQueryResponse[] | undefined;
}

export class UserReadQueryResponse {
  idUser: number | undefined;
  idUserState: UserStateEnum | undefined;
  idUserType: UserTypeEnum | undefined;
  idLanguageCulture: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  activationCode: string | undefined;
  username: string | undefined;
  password: string | undefined;
  salt: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  userRole: string | undefined;
  lockedDate: moment.Moment | undefined;
  activationDate: moment.Moment | undefined;
}

export class UserReadUserInfoQueryResponse {
  username: string | undefined;
  idTipoUtente: UserTypeEnum | undefined;
  tipoUtente: string | undefined;
  nomeLavoro: string | undefined;
  nomeAeroporto: string | undefined;
  lingua: LanguageCulture | undefined;
}

export class UserResetPasswordCommand {
  usernameOrEmail: string | undefined;
  verificationCode: string | undefined;
  password: string | undefined;
  confirmPassword: string | undefined;
}

export class UserState {
  idUserState: UserStateEnum | undefined;
  name: string | undefined;
}

export enum UserStateEnum {
  ACTIVE = 1,
  INACTIVE = 2,
  CANCELED = 3,
}

export class UserType {
  idUserType: UserTypeEnum | undefined;
  name: string | undefined;
}

export enum UserTypeEnum {
  ADMIN = 1,
  AIRLINE_COMPANY_ADMIN = 2,
  HANDLER = 3,
  HOTEL_ADMIN = 4,
  TRANSPORT = 5,
  PASSENGER = 6,
  FOOD_SERVICE_COMPANY_ADMIN = 7,
  FOOD_SERVICE_COMPANY_USER = 8,
  HOTEL_USER = 9,
  HOTEL_HOTAC = 10,
  LAYOVER_OPERATOR = 11,
}

export class UserUpdateCommand {
  idUser: number | undefined;
  name: string | undefined;
  surname: string | undefined;
  email: string | undefined;
  prefix: string | undefined;
  phoneNumber: string | undefined;
  userRole: string | undefined;
}

export class UserVerifyResetPasswordCodeCommand {
  usernameOrEmail: string | undefined;
  verificationCode: string | undefined;
}

export class VoucherSnack {
  idSnackType: number | undefined;
  name: string | undefined;
  amount: number | undefined;
  backgroundColor: string | undefined;
  textColor: string | undefined;
  totalAmountVoucherSnack: number | undefined;
  totalVoucherSnack: number | undefined;
}

export class VoucherSnackActivateCommand {
  idServiceRequestSnack: number | undefined;
  vouchers: string[] | undefined;
}

export class VoucherSnackActivateCommandResponse {
  activationErrors: ActivationError[] | undefined;
}

export class VoucherSnackActivateWithBoardingPassCommand {
  boardinPass: string | undefined;
  idServiceRequestSnack: number | undefined;
  vouchers: string[] | undefined;
}

export class VoucherSnackActivateWithBoardingPassCommandResponse {
  activationErrors: ActivationError[] | undefined;
}

export class VoucherSnackActivationSummary {
  idVoucherSnackActivation: number | undefined;
  idServiceSnackBoardingPassScan: number | undefined;
  idServiceSnack: number | undefined;
  passengerName: string | undefined;
  passengerSurname: string | undefined;
  passengerPnr: string | undefined;
  snackTypeName: string | undefined;
  snackAmount: number | undefined;
  backgroundColor: string | undefined;
  textColor: string | undefined;
}

export class VoucherSnackCreateCommand {
  idAirlineCompany: number | undefined;
  idSnackType: number | undefined;
  quantity: number | undefined;
}

export class VoucherSnackListResponse {
  items: VoucherSnackResponse[] | undefined;
}

export class VoucherSnackManagementType {
  idVoucherSnackManagementType: VoucherSnackManagementTypeEnum | undefined;
  name: string | undefined;
}

export enum VoucherSnackManagementTypeEnum {
  VOUCHER = 1,
  VOUCHER_AND_BOARDING_PASS = 2,
  BOARDING_PASS = 3,
}

export class VoucherSnackResponse {
  idVoucherSnackRequestEmission: number | undefined;
  idSnackType: number | undefined;
  idAirlineCompany: number | undefined;
  airlineCompanyName: string | undefined;
  name: string | undefined;
  quantity: number | undefined;
  printUrl: string | undefined;
  value: string | undefined;
  idUserCreatedBy: number | undefined;
}

export class WorkItem {
  idWorkItem: number | undefined;
  idAirport: number | undefined;
  reprotectionDayFrom: moment.Moment | undefined;
  estimateNumberRooms: number | undefined;
  processingStatus: number | undefined;
  notes: string | undefined;
  reprotectionDayTo: moment.Moment | undefined;
  reference: string | undefined;
}

export class WorkItemCreateCommand {
  idAirport: number | undefined;
  reprotectionDayFrom: moment.Moment | undefined;
  estimateNumberRooms: number | undefined;
  processingStatus: number | undefined;
  notes: string | undefined;
  reprotectionDayTo: moment.Moment | undefined;
  reference: string | undefined;
}

export class WorkItemDeleteCommand {
  idWorkItem: number | undefined;
}

export class WorkItemListQueryResponse {
  items: WorkItem[] | undefined;
}

export class WorkItemUpdateCommand {
  idWorkItem: number | undefined;
  idAirport: number | undefined;
  reprotectionDayFrom: moment.Moment | undefined;
  reprotectionDayTo: moment.Moment | undefined;
  estimateNumberRooms: number | undefined;
  processingStatus: number | undefined;
  notes: string | undefined;
  reference: string | undefined;
}

export class WorkItemsHotel {
  idWorkItemsHotel: number | undefined;
  idWorkItem: number | undefined;
  idHotel: number | undefined;
  idAvailability: number | undefined;
  notes: string | undefined;
  idOperator: number | undefined;
  createdAt: moment.Moment | undefined;
  updateAt: moment.Moment | undefined;
  mealAvailability: number | undefined;
  mealPrice: number | undefined;
  singleRoomNumber: number | undefined;
  singleRoomPrice: number | undefined;
  doubleRoomNumber: number | undefined;
  doubleRoomPrice: number | undefined;
  tripleRoomNumber: number | undefined;
  tripleRoomPrice: number | undefined;
  cityTax: number | undefined;
  hotel: Hotel | undefined;
  workItem: WorkItem | undefined;
}

export class WorkItemsHotelCreateCommand {
  idWorkItem: number | undefined;
  idHotel: number | undefined;
  idAvailability: number | undefined;
  notes: string | undefined;
  idOperator: number | undefined;
  createdAt: moment.Moment | undefined;
  updateAt: moment.Moment | undefined;
  mealAvailability: number | undefined;
  mealPrice: number | undefined;
  singleRoomNumber: number | undefined;
  singleRoomPrice: number | undefined;
  doubleRoomNumber: number | undefined;
  doubleRoomPrice: number | undefined;
  tripleRoomNumber: number | undefined;
  tripleRoomPrice: number | undefined;
  cityTax: number | undefined;
}

export class WorkItemsHotelDeleteCommand {
  idWorkItemsHotel: number | undefined;
}

export class WorkItemsHotelUpdateCommand {
  idWorkItemsHotel: number | undefined;
  idWorkItem: number | undefined;
  idHotel: number | undefined;
  idAvailability: number | undefined;
  notes: string | undefined;
  idOperator: number | undefined;
  createdAt: moment.Moment | undefined;
  updateAt: moment.Moment | undefined;
  mealAvailability: number | undefined;
  mealPrice: number | undefined;
  singleRoomNumber: number | undefined;
  singleRoomPrice: number | undefined;
  doubleRoomNumber: number | undefined;
  doubleRoomPrice: number | undefined;
  tripleRoomNumber: number | undefined;
  tripleRoomPrice: number | undefined;
  cityTax: number | undefined;
}

export class TicketFile_Create {
  file: File | undefined;
  id: number | undefined;
}



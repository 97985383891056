import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_LOGIN, APP_SITO, APP_QR_CODE, APP_PASSEGGERI, APP_FOOD_SERVICE_COMPANY_USER, APP_HOTAC } from './core/utils/constants';

const routes: Routes = [
  { path: APP_LOGIN, loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: APP_QR_CODE, loadChildren: () => import('./qr-code-registration/qr-code-registration.module').then(m => m.QrCodeRegistrationModule) },
  { path: APP_PASSEGGERI, loadChildren: () => import('./passeggeri/passeggeri.module').then(m => m.PasseggeriModule) },
  { path: APP_FOOD_SERVICE_COMPANY_USER, loadChildren: () => import('./food-service-company-user/food-service-company-user.module').then(m => m.FoodServiceCompanyUserModule) },
  { path: APP_SITO, loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule) },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bed-bank-hotel-th',
  templateUrl: './bed-bank-hotel-th.component.html',
  styleUrls: ['./bed-bank-hotel-th.component.css']
})
export class BedBankHotelThComponent {
  @Input() today: boolean = false;
  @Input() title?: string;
}

export const environment = {
    production: false,
    TOKEN_URL: 'https://layover-api-demo.azurewebsites.net/api/v1/auth/token',
    REFRESH_URL: 'https://layover-api-demo.azurewebsites.net/api/v1/auth/refreshToken',
    BASE_URL: 'https://layover-api-demo.azurewebsites.net',
    API_URL: 'api',
    DEFAULT_LANGUAGE: 'it-IT',
    APP_NAME: 'Layover',
    ENV: 'Demo',
    googleApiKey: 'AIzaSyBjD9iQMx5UUvVeexLHlru4zci4Xwr52Ik',
  };
  
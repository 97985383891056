import { NgModule } from '@angular/core';
import { CustomCurrencyPipe } from './custom-currency.pipe';
import localeIt from '@angular/common/locales/it';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeIt);

@NgModule({
  declarations: [
    CustomCurrencyPipe
  ],
  exports: [
    CustomCurrencyPipe
  ],
})
export class CustomCurrencyModule { }

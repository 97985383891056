import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { BehaviorSubject } from 'rxjs';
import { TailwindBreakpoint } from '../models/tailwind-breakpoint';

@Injectable({
  providedIn: 'root'
})
export class ViewBreakpointsService {

  private _tailwindBreakpointSubject: BehaviorSubject<TailwindBreakpoint | null>;

  public get tailwindBreakpoint$() { return this._tailwindBreakpointSubject.asObservable(); }
  public get tailwindBreakpoint() { return this._tailwindBreakpointSubject.value; }

  constructor(
    private readonly _breakpointObserver: BreakpointObserver
  ) {
    this._tailwindBreakpointSubject = new BehaviorSubject<TailwindBreakpoint | null>(null);
    
    this._initialize();
  }

  private _initialize() {
    this._breakpointObserver
      .observe([
        '(min-width: 640px)',
        '(min-width: 768px)',
        '(min-width: 1024px)',
        '(min-width: 1280px)',
        '(min-width: 1536px)'
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints['(min-width: 1536px)'])
          this._tailwindBreakpointSubject.next(TailwindBreakpoint.XXL);
        else if (state.breakpoints['(min-width: 1280px)'])
          this._tailwindBreakpointSubject.next(TailwindBreakpoint.XL);
        else if (state.breakpoints['(min-width: 1024px)'])
          this._tailwindBreakpointSubject.next(TailwindBreakpoint.LG);
        else if (state.breakpoints['(min-width: 768px)'])
          this._tailwindBreakpointSubject.next(TailwindBreakpoint.MD);
        else if (state.breakpoints['(min-width: 640px)'])
          this._tailwindBreakpointSubject.next(TailwindBreakpoint.SM);
        else
          this._tailwindBreakpointSubject.next(TailwindBreakpoint.XS);

        console.debug('Viewport is ' + window.innerWidth + ' ' + this._tailwindBreakpointSubject.value);
      });
  }

  public getModalOptions(): MatDialogConfig {
    if (this._tailwindBreakpointSubject.value == TailwindBreakpoint.XS
      || this._tailwindBreakpointSubject.value == TailwindBreakpoint.SM) {
      return { maxWidth: '100vw', width: '100%', autoFocus: false, hasBackdrop: true };
    } /*else if (this._tailwindBreakpointSubject.value != null && this._tailwindBreakpointSubject.value == TailwindBreakpoint.MD) {
      return { maxWidth: '700px', width: '700px' };
    } else if (this._tailwindBreakpointSubject.value != null && this._tailwindBreakpointSubject.value >= TailwindBreakpoint.LG) {
      return { maxWidth: '850px', width: '850px' };
    }*/
    return { autoFocus: false, hasBackdrop: true };
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
    name: 'momentFormat'
})
export class MomentFormatPipe implements PipeTransform {

    constructor(
        private readonly _language: LanguageService,
        private readonly _translate: TranslateService) {

    }

    transform(value: moment.Moment | undefined, formatType: MomentFormatType): string | undefined {
        if (value == null || value == undefined) return undefined;

        if (!value.isValid()) throw new Error('MomentFormatPipe - Invalid moment date');

        if (formatType) {
            if (formatType != MomentFormatType.DATE
                && formatType != MomentFormatType.DATETIME
                && formatType != MomentFormatType.TIME) {
                throw new Error('MomentFormatPipe - Invalid argument formatType');
            }
        }

        switch (formatType) {
            case MomentFormatType.DATE:
                return value.format(this._translate.instant('COMMON.FORMAT_DATE'));
            case MomentFormatType.DATETIME:
                return value.format(this._translate.instant('COMMON.FORMAT_DATETIME'));
            case MomentFormatType.TIME:
                return value.format(this._translate.instant('COMMON.FORMAT_TIME'));
            default:
                return value.format(this._translate.instant('COMMON.FORMAT_DATETIME'));
        }
    }

}

export enum MomentFormatType {
    DATE = 1,
    DATETIME = 2,
    TIME = 3,
}
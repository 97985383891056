import { Component } from '@angular/core';
import { SignalRService } from './core/services/signal-r.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Layover Tech Solutions';

  constructor(
    private readonly _signalR: SignalRService,
  ) {
    this._signalR.startConnection();
  }

}

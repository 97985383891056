import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bed-bank-city-th',
  templateUrl: './bed-bank-city-th.component.html',
  styleUrls: ['./bed-bank-city-th.component.css']
})
export class BedBankCityThComponent {
  @Input() today: boolean = false;
  @Input() title?: string;

}

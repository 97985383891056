import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { CodeScannerComponent } from 'src/app/sharedmodules/code-scanner/code-scanner.component';
import { ServiceSnackBoardingPassScan } from '../../models/service-snack-boarding-pass-scan.model';

@Component({
  selector: 'boarding-pass-scanner',
  templateUrl: './boarding-pass-scanner.component.html',
  styleUrls: ['./boarding-pass-scanner.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    CodeScannerComponent
  ],
})
export class BoardingPassScannerComponent {

  @Output() boardingPassScanSucceeded = new EventEmitter<ServiceSnackBoardingPassScan>();

  public supportedBarcodeFormats: BarcodeFormat[] = [BarcodeFormat.AZTEC, BarcodeFormat.PDF_417, BarcodeFormat.DATA_MATRIX, BarcodeFormat.QR_CODE];
  public currentDevice?: MediaDeviceInfo;

  constructor() { }

  onScanSuccess(resultString: string) {
    console.debug('BoardingPassScannerComponent - scan', resultString);

    const stopovers = resultString.substring(0, 2).trim();
    const surnameName = resultString.substring(2, 22).trim();
    const pnr = resultString.substring(22, 30).trim();
    const flightInfo = resultString.substring(30, 38).trim();
    const flightNumber = resultString.substring(38, 43).trim();
    const dateAndSeat = resultString.substring(43, 56).trim();

    const result = new ServiceSnackBoardingPassScan();
    result.code = resultString;
    result.stopovers = stopovers;
    result.surname = surnameName.split('/')[0];
    result.name = surnameName.split('/')[1];
    result.pnr = pnr;
    result.departureAirportIata = flightInfo.substring(0, 3);
    result.destinationAirportIata = flightInfo.substring(3, 6);
    result.airlineCompanyIata = flightInfo.substring(6);
    result.flightNumber = flightNumber;
    result.dayOfTheYear = (Number)(dateAndSeat.substring(0, 3));
    result.cabin = dateAndSeat.substring(3, 4);
    result.seat = dateAndSeat.substring(4, 8);
    result.seq = dateAndSeat.substring(8);

    this.boardingPassScanSucceeded.emit(result);
  }

  onScanComplete(event: any) {
    // console.debug(event)
  }
}

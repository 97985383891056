import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { BoardingPassScannerComponent } from '../sharedmodules/boarding-pass-scanner/components/boarding-pass-scanner/boarding-pass-scanner.component';
import { CustomCurrencyModule } from '../sharedmodules/custom-currency/custom-currency.module';
import { DateTimeFormComponent } from '../sharedmodules/date-time-form/date-time-form.component';
import { LoaderModule } from '../sharedmodules/loader/loader.module';
import { MomentFormatModule } from '../sharedmodules/moment-format/moment-format.module';
import { BedBankRoutingModule } from './bed-bank-routing.module';
import { BedBankAirportListComponent } from './components/bed-bank-city-list/bed-bank-city-list.component';
import { BedBankHotelListComponent } from './components/bed-bank-hotel-list/bed-bank-hotel-list.component';
import { BedBankCityPageComponent } from './pages/bed-bank-city-page/bed-bank-city-page.component';
import { BedBankHotelPageComponent } from './pages/bed-bank-hotel-page/bed-bank-hotel-page.component';
import { BedBankCityThComponent } from './components/bed-bank-city-th/bed-bank-city-th.component';
import { BedBankCityTdComponent } from './components/bed-bank-city-td/bed-bank-city-td.component';
import { BedBankHotelThComponent } from './components/bed-bank-hotel-th/bed-bank-hotel-th.component';
import { BedBankHotelTdComponent } from './components/bed-bank-hotel-td/bed-bank-hotel-td.component';


@NgModule({
  declarations: [
    BedBankCityPageComponent,
    BedBankHotelPageComponent,
    BedBankAirportListComponent,
    BedBankHotelListComponent,
    BedBankCityThComponent,
    BedBankCityTdComponent,
    BedBankHotelThComponent,
    BedBankHotelTdComponent
  ],
  imports: [
    CommonModule,
    BedBankRoutingModule,
    ReactiveFormsModule,
    LoaderModule,
    FormsModule,
    MatDialogModule,
    TranslateModule.forChild(),
    MatIconModule,
    MomentFormatModule,
    CustomCurrencyModule,
    BoardingPassScannerComponent,
    DateTimeFormComponent,
    MatTableModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatPaginatorModule,
  ],
})
export class BedBankModule { }

import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BedBankAirportRoom } from 'src/app/core/autogenerated/model.autogenerated';

@Component({
  selector: 'app-bed-bank-city-td',
  templateUrl: './bed-bank-city-td.component.html',
  styleUrls: ['./bed-bank-city-td.component.css']
})
export class BedBankCityTdComponent implements OnInit {
  @Input() bedBankAirportRoom?: BedBankAirportRoom;
  @Input() today: boolean = false;

  public hotels: number = 0;
  public rooms: number = 0;
  public occupancy: number = 0;
  

  public tooltip: string = '';

  constructor(private readonly _translate: TranslateService) {
  }

  ngOnInit(): void {
    const singleTooltip = this._translate.instant('BED_BANK.SINGLES') + ': ' + (this.bedBankAirportRoom?.singleRooms ?? 0);
    const doubleTooltip = this._translate.instant('BED_BANK.DOUBLES') + ': ' + (this.bedBankAirportRoom?.doubleRooms ?? 0);
    const tripleTooltip = this._translate.instant('BED_BANK.TRIPLES') + ': ' + (this.bedBankAirportRoom?.tripleRooms ?? 0);

    this.tooltip = [singleTooltip, doubleTooltip, tripleTooltip].join(`; `);

    if (this.bedBankAirportRoom) {
      this.hotels = this.bedBankAirportRoom.hotels ?? 0;

      this.rooms = (this.bedBankAirportRoom.singleRooms ?? 0) +
                   (this.bedBankAirportRoom.doubleRooms ?? 0) +
                   (this.bedBankAirportRoom.tripleRooms ?? 0);

      this.occupancy = (this.bedBankAirportRoom.singleRooms ?? 0) +
                   2 * (this.bedBankAirportRoom.doubleRooms ?? 0) +
                   3 * (this.bedBankAirportRoom.tripleRooms ?? 0);
    }
  }
}

import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Observable } from 'rxjs';
import { exhaustMap, map } from 'rxjs/operators';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LinguaModule } from './sharedmodules/lingua/lingua.module';
import { LoaderModule } from './sharedmodules/loader/loader.module';
import { MessageComponent } from './core/components/message/message.component';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogModule as MatDialogModule, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { environment } from 'src/environments/environment';
import { UserService } from './core/services/user.service';
import { LanguageService } from './core/services/language.service';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppStateModule } from './store/app/app-state.module';
import { JwtAuthModule, StorageType } from '@devlearning/jwt-auth';
import { MutexFastLockModule } from '@devlearning/mutex-fast-lock';
import { EnumsService } from './core/services/enums.service';
import { GlobalErrorHandler } from './core/global-error-handler';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UpdateBlinkerComponent } from './core/components/update-blinker/update-blinker.component';
import { BedBankModule } from './bed-bank/bed-bank.module';

@NgModule({
  declarations: [
    AppComponent,
    MessageComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.DEFAULT_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MutexFastLockModule.forRoot({
      lockPrefix: '_MUTEX_LOCK',
      timeout: 30000,
      debugEnabled: false
    }),
    JwtAuthModule.forRoot({
      logLevel: 1,
      tokenUrl: environment.TOKEN_URL,
      refreshUrl: environment.REFRESH_URL,
      useManualInitialization: true,
      storageType: StorageType.SESSION_STORAGE
    }),
    BrowserAnimationsModule,
    BedBankModule,
    MatDialogModule,
    MatButtonModule,
    CdkAccordionModule,
    LinguaModule,
    LoaderModule,
    LayoutModule,
    EffectsModule.forRoot([]),
    AppStateModule,
    StoreDevtoolsModule.instrument({
      name: 'layover-app-admin',
      maxAge: 25,
      logOnly: environment.production
    }),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [
        LanguageService,
        UserService,
        EnumsService
      ]
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

function initializeAppFactory(
  language: LanguageService,
  user: UserService,
  enums: EnumsService): () => Observable<any> {
  return () => enums.initialize()
    .pipe(
      exhaustMap(x => user.initialize()),
      exhaustMap(x => language.initialize(x.datiUtente?.lingua!)),
      map(x => true)
    );
}

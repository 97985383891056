export class ServiceSnackBoardingPassScan {
    public idServiceSnackBoardingPassScan: number | undefined;
    public idServiceSnack: number | undefined;
    public idUserCreated: number | undefined;
    public code: string | undefined;
    public stopovers: string | undefined;
    public surname: string | undefined;
    public name: string | undefined;
    public pnr: string | undefined;
    public departureAirportIata: string | undefined;
    public destinationAirportIata: string | undefined;
    public airlineCompanyIata: string | undefined;
    public flightNumber: string | undefined;
    public dayOfTheYear: number | undefined;
    public cabin: string | undefined;
    public seat: string | undefined;
    public seq: string | undefined;
}

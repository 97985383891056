import { HttpClient } from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { httpOptions, httpOptionsMultipart } from 'src/app/core/utils/http-options';
import * as Models from './model.autogenerated';

export abstract class ApiAutogeneratedService {
  constructor(
    public _http: HttpClient,
    public _baseUrl: string,
  ) { }

  protected abstract _momentToString(moment: moment.Moment): string;
  protected abstract _handleRequest<T>(request: T): T;
  protected abstract _handleMultipart<T>(request: T): FormData;
  protected abstract _handleResponse<T>(response: T): T;
  protected abstract _handleError(error: any, obs: any): Observable<never>;


  public AirlineCompany_Read(idAirlineCompany: number): Observable<Models.AirlineCompany> {
    let idAirlineCompanyParam: string = encodeURIComponent('' + idAirlineCompany);
    return this._http.get<Models.AirlineCompany>(`${this._baseUrl}/api/v1/AirlineCompany/Read?idAirlineCompany=${idAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_List(filter?: string): Observable<Models.AirlineCompanyListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.AirlineCompanyListQueryResponse>(`${this._baseUrl}/api/v1/AirlineCompany/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_ListUsers(idAirlineCompany: number, idUser: number): Observable<Models.AirlineCompanyListUsersQueryResponse> {
    let idAirlineCompanyParam: string = encodeURIComponent('' + idAirlineCompany);
    let idUserParam: string = encodeURIComponent('' + idUser);
    return this._http.get<Models.AirlineCompanyListUsersQueryResponse>(`${this._baseUrl}/api/v1/AirlineCompany/ListUsers?idAirlineCompany=${idAirlineCompanyParam}&idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_Create(request: Models.AirlineCompanyCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/AirlineCompany/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_Update(request: Models.AirlineCompanyUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/AirlineCompany/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_Delete(idAirlineCompany?: number): Observable<Models.Result> {
    let idAirlineCompanyParam: string = idAirlineCompany != null && idAirlineCompany != undefined ? encodeURIComponent('' + idAirlineCompany) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/AirlineCompany/Delete?idAirlineCompany=${idAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_ListAirports(idAirlineCompany?: number): Observable<Models.AirlineCompanyListAirportsQueryResponse> {
    let idAirlineCompanyParam: string = idAirlineCompany != null && idAirlineCompany != undefined ? encodeURIComponent('' + idAirlineCompany) : '';
    return this._http.get<Models.AirlineCompanyListAirportsQueryResponse>(`${this._baseUrl}/api/v1/AirlineCompany/ListAirports?idAirlineCompany=${idAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_CreateAirlineCompanyInAirportByIdUser(idAirlineCompany?: number, idAirport?: number): Observable<number> {
    let idAirlineCompanyParam: string = idAirlineCompany != null && idAirlineCompany != undefined ? encodeURIComponent('' + idAirlineCompany) : '';
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    return this._http.post<number>(`${this._baseUrl}/api/v1/AirlineCompany/CreateAirlineCompanyInAirportByIdUser?idAirlineCompany=${idAirlineCompanyParam}&idAirport=${idAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_ReadByIdUser(idUser?: number): Observable<Models.AirlineCompany> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    return this._http.get<Models.AirlineCompany>(`${this._baseUrl}/api/v1/AirlineCompany/ReadByIdUser?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompany_ImportHandlerIntoAirport(username?: string, idAirport?: number, idAirlineCompany?: number): Observable<Models.Result> {
    let usernameParam: string = username != null && username != undefined ? encodeURIComponent('' + username) : '';
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    let idAirlineCompanyParam: string = idAirlineCompany != null && idAirlineCompany != undefined ? encodeURIComponent('' + idAirlineCompany) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/AirlineCompany/ImportHandlerIntoAirport?username=${usernameParam}&idAirport=${idAirportParam}&idAirlineCompany=${idAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompanyInAirport_Create(request: Models.AirlineCompanyInAirportCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/AirlineCompanyInAirport/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirlineCompanyInAirport_Delete(idAirlineCompanyInAirport?: number): Observable<Models.Result> {
    let idAirlineCompanyInAirportParam: string = idAirlineCompanyInAirport != null && idAirlineCompanyInAirport != undefined ? encodeURIComponent('' + idAirlineCompanyInAirport) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/AirlineCompanyInAirport/Delete?idAirlineCompanyInAirport=${idAirlineCompanyInAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_Read(idAirport?: number): Observable<Models.Airport> {
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    return this._http.get<Models.Airport>(`${this._baseUrl}/api/v1/Airport/Read?idAirport=${idAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_ReadByCurrentUser(): Observable<Models.Airport> {
    return this._http.get<Models.Airport>(`${this._baseUrl}/api/v1/Airport/ReadByCurrentUser`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_List(filter?: string): Observable<Models.AirportListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.AirportListQueryResponse>(`${this._baseUrl}/api/v1/Airport/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Airport_ListManaged(request: Models.AirportManagedListQuery): Observable<Models.AirportManagedListQueryResponse> {
    let requestParam: string = request != null && request != undefined ? encodeURIComponent('' + request) : '';
    return this._http.get<Models.AirportManagedListQueryResponse>(`${this._baseUrl}/api/v1/Airport/ListManaged?request=${requestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_Read(idAirportHasHotel?: number): Observable<Models.AirportHasHotel> {
    let idAirportHasHotelParam: string = idAirportHasHotel != null && idAirportHasHotel != undefined ? encodeURIComponent('' + idAirportHasHotel) : '';
    return this._http.get<Models.AirportHasHotel>(`${this._baseUrl}/api/v1/AirportHasHotel/Read?idAirportHasHotel=${idAirportHasHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_ListAirportByHotel(idHotel?: number): Observable<Models.AirportHasHotelListAirportByHotelQueryResponse> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    return this._http.get<Models.AirportHasHotelListAirportByHotelQueryResponse>(`${this._baseUrl}/api/v1/AirportHasHotel/ListAirportByHotel?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_Create(request: Models.AirportHasHotelCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/AirportHasHotel/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_Update(request: Models.AirportHasHotelUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/AirportHasHotel/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public AirportHasHotel_Delete(request: Models.AirportHasHotelDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/AirportHasHotel/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_token(request: Models.AuthTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/token`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_refreshToken(request: Models.AuthRefreshTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/refreshToken`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_TokenHotac(request: Models.AuthTokenHotacCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/TokenHotac`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Auth_TokenByIdAuthJwtToken(request: Models.AuthTokenByIdAuthJwtTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/Auth/TokenByIdAuthJwtToken`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public BedBank_BedBankAirportList(request: Models.BedBankAirportListQuery): Observable<Models.BedBankAirportListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.BedBankAirportListQueryResponse>(`${this._baseUrl}/api/v1/BedBank/BedBankAirportList`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public BedBank_BedBankHotelList(request: Models.BedBankHotelListQuery): Observable<Models.BedBankHotelListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.BedBankHotelListQueryResponse>(`${this._baseUrl}/api/v1/BedBank/BedBankHotelList`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ContractPlanRate_Read(idContract: number): Observable<Models.ContractPlanRateReadQueryResponse> {
    let idContractParam: string = encodeURIComponent('' + idContract);
    return this._http.get<Models.ContractPlanRateReadQueryResponse>(`${this._baseUrl}/api/v1/ContractPlanRate/Read?idContract=${idContractParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Country_List(filter?: string): Observable<Models.CountryListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.CountryListQueryResponse>(`${this._baseUrl}/api/v1/Country/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Enum_ListAll(): Observable<Models.EnumListQueryResponse> {
    return this._http.get<Models.EnumListQueryResponse>(`${this._baseUrl}/api/v1/Enum/ListAll`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Enum_ListHotelArea(): Observable<Models.EnumListHotelAreaQueryResponse> {
    return this._http.get<Models.EnumListHotelAreaQueryResponse>(`${this._baseUrl}/api/v1/Enum/ListHotelArea`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompany_Read(idFoodServiceCompany: number): Observable<Models.FoodServiceCompany> {
    let idFoodServiceCompanyParam: string = encodeURIComponent('' + idFoodServiceCompany);
    return this._http.get<Models.FoodServiceCompany>(`${this._baseUrl}/api/v1/FoodServiceCompany/Read?idFoodServiceCompany=${idFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompany_List(filter?: string): Observable<Models.FoodServiceCompanyListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.FoodServiceCompanyListQueryResponse>(`${this._baseUrl}/api/v1/FoodServiceCompany/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompany_ListUsers(idFoodServiceCompany: number, idUser: number): Observable<Models.FoodServiceCompanyListUsersQueryResponse> {
    let idFoodServiceCompanyParam: string = encodeURIComponent('' + idFoodServiceCompany);
    let idUserParam: string = encodeURIComponent('' + idUser);
    return this._http.get<Models.FoodServiceCompanyListUsersQueryResponse>(`${this._baseUrl}/api/v1/FoodServiceCompany/ListUsers?idFoodServiceCompany=${idFoodServiceCompanyParam}&idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompany_Create(request: Models.FoodServiceCompanyCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/FoodServiceCompany/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompany_Update(request: Models.FoodServiceCompanyUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/FoodServiceCompany/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompany_Delete(idFoodServiceCompany?: number): Observable<Models.Result> {
    let idFoodServiceCompanyParam: string = idFoodServiceCompany != null && idFoodServiceCompany != undefined ? encodeURIComponent('' + idFoodServiceCompany) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/FoodServiceCompany/Delete?idFoodServiceCompany=${idFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompany_ReadByIdUser(idUser?: number): Observable<Models.FoodServiceCompany> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    return this._http.get<Models.FoodServiceCompany>(`${this._baseUrl}/api/v1/FoodServiceCompany/ReadByIdUser?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompanyUser_ListVoucherSnackByBoardingPass(boardingPass: string): Observable<Models.FoodServiceCompanyUserListVoucherSnackByBoardingPassResponse> {
    let boardingPassParam: string = encodeURIComponent('' + boardingPass);
    return this._http.get<Models.FoodServiceCompanyUserListVoucherSnackByBoardingPassResponse>(`${this._baseUrl}/api/v1/FoodServiceCompanyUser/ListVoucherSnackByBoardingPass?boardingPass=${boardingPassParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompanyUser_ListBoardingPassScanned(): Observable<Models.FoodServiceCompanyVoucherSnackBoardingPassScannedListResponse> {
    return this._http.get<Models.FoodServiceCompanyVoucherSnackBoardingPassScannedListResponse>(`${this._baseUrl}/api/v1/FoodServiceCompanyUser/ListBoardingPassScanned`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompanyUser_EmitVoucherSnack(request: Models.FoodServiceCompanyUserEmitVoucherSnackCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/FoodServiceCompanyUser/EmitVoucherSnack`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public FoodServiceCompanyVoucherSnack_DownloadOrderForm(idTicket: number, idFoodServiceCompany: number): Observable<string> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    let idFoodServiceCompanyParam: string = encodeURIComponent('' + idFoodServiceCompany);
    return this._http.post<string>(`${this._baseUrl}/api/v1/FoodServiceCompanyVoucherSnack/DownloadOrderForm?idTicket=${idTicketParam}&idFoodServiceCompany=${idFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelAttachment_Read(idHotelAttachment?: number): Observable<Models.HotelAttachment> {
    let idHotelAttachmentParam: string = idHotelAttachment != null && idHotelAttachment != undefined ? encodeURIComponent('' + idHotelAttachment) : '';
    return this._http.get<Models.HotelAttachment>(`${this._baseUrl}/api/v1/HotelAttachment/Read?idHotelAttachment=${idHotelAttachmentParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelAttachment_List(idHotel?: number): Observable<Models.HotelAttachmentListQueryResponse> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    return this._http.get<Models.HotelAttachmentListQueryResponse>(`${this._baseUrl}/api/v1/HotelAttachment/List?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelAttachment_Create(request: Models.HotelAttachmentCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelAttachment/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelAttachment_Update(request: Models.HotelAttachmentUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelAttachment/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelAttachment_Delete(request: Models.HotelAttachmentDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelAttachment/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelAvailabilities_GetAvailabilityByIdAeroporto(idAirport?: number, date?: moment.Moment): Observable<Models.StopoverAvailability> {
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    let dateParam: string = date != null && date != undefined && date.isValid() ? encodeURIComponent(this._momentToString(date)) : '';
    return this._http.get<Models.StopoverAvailability>(`${this._baseUrl}/api/v1/HotelAvailabilities/GetAvailabilityByIdAeroporto?idAirport=${idAirportParam}&date=${dateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChannelManager_Read(idHotelChannelManager?: number): Observable<Models.HotelChannelManager> {
    let idHotelChannelManagerParam: string = idHotelChannelManager != null && idHotelChannelManager != undefined ? encodeURIComponent('' + idHotelChannelManager) : '';
    return this._http.get<Models.HotelChannelManager>(`${this._baseUrl}/api/v1/HotelChannelManager/Read?idHotelChannelManager=${idHotelChannelManagerParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChannelManager_List(request: Models.HotelChannelManagerListQuery): Observable<Models.HotelChannelManagerListQueryResponse> {
    let requestParam: string = request != null && request != undefined ? encodeURIComponent('' + request) : '';
    return this._http.get<Models.HotelChannelManagerListQueryResponse>(`${this._baseUrl}/api/v1/HotelChannelManager/List?request=${requestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChannelManager_Create(request: Models.HotelChannelManagerCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelChannelManager/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChannelManager_Update(request: Models.HotelChannelManagerUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelChannelManager/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelChannelManager_Delete(request: Models.HotelChannelManagerDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelChannelManager/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelCommunication_Read(idHotelCommunication?: number): Observable<Models.HotelCommunication> {
    let idHotelCommunicationParam: string = idHotelCommunication != null && idHotelCommunication != undefined ? encodeURIComponent('' + idHotelCommunication) : '';
    return this._http.get<Models.HotelCommunication>(`${this._baseUrl}/api/v1/HotelCommunication/Read?idHotelCommunication=${idHotelCommunicationParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelCommunication_List(idHotel?: number): Observable<Models.HotelCommunicationListQueryResponse> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    return this._http.get<Models.HotelCommunicationListQueryResponse>(`${this._baseUrl}/api/v1/HotelCommunication/List?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelCommunication_Create(request: Models.HotelCommunicationCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelCommunication/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelCommunication_Update(request: Models.HotelCommunicationUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelCommunication/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelCommunication_Delete(request: Models.HotelCommunicationDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelCommunication/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelContact_Read(idHotelContact?: number): Observable<Models.HotelContact> {
    let idHotelContactParam: string = idHotelContact != null && idHotelContact != undefined ? encodeURIComponent('' + idHotelContact) : '';
    return this._http.get<Models.HotelContact>(`${this._baseUrl}/api/v1/HotelContact/Read?idHotelContact=${idHotelContactParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelContact_List(idHotel?: number): Observable<Models.HotelContactListQueryResponse> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    return this._http.get<Models.HotelContactListQueryResponse>(`${this._baseUrl}/api/v1/HotelContact/List?idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelContact_Create(request: Models.HotelContactCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelContact/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelContact_Update(request: Models.HotelContactUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelContact/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelContact_Delete(request: Models.HotelContactDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelContact/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelGroup_Read(idHotelGroup?: number): Observable<Models.HotelGroup> {
    let idHotelGroupParam: string = idHotelGroup != null && idHotelGroup != undefined ? encodeURIComponent('' + idHotelGroup) : '';
    return this._http.get<Models.HotelGroup>(`${this._baseUrl}/api/v1/HotelGroup/Read?idHotelGroup=${idHotelGroupParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelGroup_List(request: Models.HotelGroupListQuery): Observable<Models.HotelGroupListQueryResponse> {
    let requestParam: string = request != null && request != undefined ? encodeURIComponent('' + request) : '';
    return this._http.get<Models.HotelGroupListQueryResponse>(`${this._baseUrl}/api/v1/HotelGroup/List?request=${requestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelGroup_Create(request: Models.HotelGroupCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelGroup/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelGroup_Update(request: Models.HotelGroupUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelGroup/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelGroup_Delete(request: Models.HotelGroupDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelGroup/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_Read(idHotelRoomCartBooking?: number): Observable<Models.HotelRoomCartBooking> {
    let idHotelRoomCartBookingParam: string = idHotelRoomCartBooking != null && idHotelRoomCartBooking != undefined ? encodeURIComponent('' + idHotelRoomCartBooking) : '';
    return this._http.get<Models.HotelRoomCartBooking>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/Read?idHotelRoomCartBooking=${idHotelRoomCartBookingParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_ListByIdServiceRequestAirportToHotel(idRichiestaServizioAeroportoToHotel?: number): Observable<Models.HotelRoomCartBookingListByIdServiceRequestAirportToHotelQueryResponse> {
    let idRichiestaServizioAeroportoToHotelParam: string = idRichiestaServizioAeroportoToHotel != null && idRichiestaServizioAeroportoToHotel != undefined ? encodeURIComponent('' + idRichiestaServizioAeroportoToHotel) : '';
    return this._http.get<Models.HotelRoomCartBookingListByIdServiceRequestAirportToHotelQueryResponse>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/ListByIdServiceRequestAirportToHotel?idRichiestaServizioAeroportoToHotel=${idRichiestaServizioAeroportoToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_Save(request: Models.HotelRoomCartBookingSaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_BookHotels(request: Models.HotelRoomCartBookingBookHotelsCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/BookHotels`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomCartBooking_Delete(idCameraHotelCarrelloPrenotazione?: number): Observable<Models.Result> {
    let idCameraHotelCarrelloPrenotazioneParam: string = idCameraHotelCarrelloPrenotazione != null && idCameraHotelCarrelloPrenotazione != undefined ? encodeURIComponent('' + idCameraHotelCarrelloPrenotazione) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/HotelRoomCartBooking/Delete?idCameraHotelCarrelloPrenotazione=${idCameraHotelCarrelloPrenotazioneParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomDailyAvailability_Save(request: Models.HotelRoomDailyAvailabilitySaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelRoomDailyAvailability/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomResult_Details(idHotelRoomResult: number): Observable<Models.HotelRoomResultDetails> {
    let idHotelRoomResultParam: string = encodeURIComponent('' + idHotelRoomResult);
    return this._http.get<Models.HotelRoomResultDetails>(`${this._baseUrl}/api/v1/HotelRoomResult/Details?idHotelRoomResult=${idHotelRoomResultParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomResult_ReadByServiceRequest(idServiceRequestAirportToHotel: number, idHotel: number, date: moment.Moment): Observable<Models.HotelRoomResultDetails> {
    let idServiceRequestAirportToHotelParam: string = encodeURIComponent('' + idServiceRequestAirportToHotel);
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    let dateParam: string = encodeURIComponent(this._momentToString(date));
    return this._http.get<Models.HotelRoomResultDetails>(`${this._baseUrl}/api/v1/HotelRoomResult/ReadByServiceRequest?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}&idHotel=${idHotelParam}&date=${dateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelRoomResult_Save(request: Models.HotelRoomResultSaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelRoomResult/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelSurvey_List(request: Models.HotelSurveyListQuery): Observable<Models.HotelSurveyListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.HotelSurveyListQueryResponse>(`${this._baseUrl}/api/v1/HotelSurvey/List`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelSurvey_Read(idHotel?: number, date?: moment.Moment): Observable<Models.HotelSurveyReadResponse> {
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    let dateParam: string = date != null && date != undefined && date.isValid() ? encodeURIComponent(this._momentToString(date)) : '';
    return this._http.get<Models.HotelSurveyReadResponse>(`${this._baseUrl}/api/v1/HotelSurvey/Read?idHotel=${idHotelParam}&date=${dateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public HotelSurvey_Save(request: Models.HotelSurveySaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/HotelSurvey/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public LanguageCulture_List(): Observable<Models.LanguageCultureListQueryResponse> {
    return this._http.get<Models.LanguageCultureListQueryResponse>(`${this._baseUrl}/api/v1/LanguageCulture/List`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_Read(idPassengerGroup?: number): Observable<Models.PassengerGroupResponse> {
    let idPassengerGroupParam: string = idPassengerGroup != null && idPassengerGroup != undefined ? encodeURIComponent('' + idPassengerGroup) : '';
    return this._http.get<Models.PassengerGroupResponse>(`${this._baseUrl}/api/v1/PassengerGroup/Read?idPassengerGroup=${idPassengerGroupParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_Save(request: Models.PassengerGroupSaveCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/PassengerGroup/Save`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_Delete(idPassengerGroup?: number): Observable<Models.Result> {
    let idPassengerGroupParam: string = idPassengerGroup != null && idPassengerGroup != undefined ? encodeURIComponent('' + idPassengerGroup) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/PassengerGroup/Delete?idPassengerGroup=${idPassengerGroupParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_List(idTicket?: number): Observable<Models.PassengerGroupListResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.PassengerGroupListResponse>(`${this._baseUrl}/api/v1/PassengerGroup/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_ListByIdServiceRequestAirportToAirport(idServiceRequestAirportToAirport?: number): Observable<Models.PassengerGroupListByIdServiceRequestAirportToAirportResponse> {
    let idServiceRequestAirportToAirportParam: string = idServiceRequestAirportToAirport != null && idServiceRequestAirportToAirport != undefined ? encodeURIComponent('' + idServiceRequestAirportToAirport) : '';
    return this._http.get<Models.PassengerGroupListByIdServiceRequestAirportToAirportResponse>(`${this._baseUrl}/api/v1/PassengerGroup/ListByIdServiceRequestAirportToAirport?idServiceRequestAirportToAirport=${idServiceRequestAirportToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_ListByIdServiceRequestAirportToHotel(idServiceRequestAirportToHotel?: number): Observable<Models.PassengerGroupListByIdServiceRequestAirportToHotelResponse> {
    let idServiceRequestAirportToHotelParam: string = idServiceRequestAirportToHotel != null && idServiceRequestAirportToHotel != undefined ? encodeURIComponent('' + idServiceRequestAirportToHotel) : '';
    return this._http.get<Models.PassengerGroupListByIdServiceRequestAirportToHotelResponse>(`${this._baseUrl}/api/v1/PassengerGroup/ListByIdServiceRequestAirportToHotel?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerGroup_ListByIdServiceRequestHotelToAirport(idServiceRequestHotelToAirport?: number): Observable<Models.PassengerGroupListByIdServiceRequestHotelToAirportResponse> {
    let idServiceRequestHotelToAirportParam: string = idServiceRequestHotelToAirport != null && idServiceRequestHotelToAirport != undefined ? encodeURIComponent('' + idServiceRequestHotelToAirport) : '';
    return this._http.get<Models.PassengerGroupListByIdServiceRequestHotelToAirportResponse>(`${this._baseUrl}/api/v1/PassengerGroup/ListByIdServiceRequestHotelToAirport?idServiceRequestHotelToAirport=${idServiceRequestHotelToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerRegistration_GenerateCode(request: Models.PassengerRegistrationGenerateCodeCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/PassengerRegistration/GenerateCode`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerRegistration_Activate(request: Models.PassengerRegistrationActivateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/PassengerRegistration/Activate`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerRegistration_Token(request: Models.PassengerRegistrationTokenCommand): Observable<Models.AuthenticationToken> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.AuthenticationToken>(`${this._baseUrl}/api/v1/PassengerRegistration/Token`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public PassengerRegistration_Finalize(request: Models.PassengerRegistrationFinalizeCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/PassengerRegistration/Finalize`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Reprotection_List(request: Models.ReprotectionListQuery): Observable<Models.ReprotectionListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.ReprotectionListQueryResponse>(`${this._baseUrl}/api/v1/Reprotection/List`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Reprotection_ListItem(idServiceRequestAirportToHotel: number, idHotel: number, date: moment.Moment): Observable<Models.HotelAvailabilityItem> {
    let idServiceRequestAirportToHotelParam: string = encodeURIComponent('' + idServiceRequestAirportToHotel);
    let idHotelParam: string = encodeURIComponent('' + idHotel);
    let dateParam: string = encodeURIComponent(this._momentToString(date));
    return this._http.get<Models.HotelAvailabilityItem>(`${this._baseUrl}/api/v1/Reprotection/ListItem?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}&idHotel=${idHotelParam}&date=${dateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequest_Read(idServiceRequest?: number): Observable<Models.ServiceRequestResponse> {
    let idServiceRequestParam: string = idServiceRequest != null && idServiceRequest != undefined ? encodeURIComponent('' + idServiceRequest) : '';
    return this._http.get<Models.ServiceRequestResponse>(`${this._baseUrl}/api/v1/ServiceRequest/Read?idServiceRequest=${idServiceRequestParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequest_List(idTicket?: number): Observable<Models.ListServiceRequestResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.ListServiceRequestResponse>(`${this._baseUrl}/api/v1/ServiceRequest/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequest_Update(request: Models.ServiceRequestUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequest/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_Read(idServiceRequestAirportToAirport?: number): Observable<Models.ServiceRequestAirportToAirportResponse> {
    let idServiceRequestAirportToAirportParam: string = idServiceRequestAirportToAirport != null && idServiceRequestAirportToAirport != undefined ? encodeURIComponent('' + idServiceRequestAirportToAirport) : '';
    return this._http.get<Models.ServiceRequestAirportToAirportResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/Read?idServiceRequestAirportToAirport=${idServiceRequestAirportToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_List(idTicket?: number): Observable<Models.ServiceRequestAirportToAirportListResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.ServiceRequestAirportToAirportListResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_Create(request: Models.ServiceRequestAirportToAirportCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_Update(request: Models.ServiceRequestAirportToAirportUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_Delete(idServiceRequestAirportToAirport?: number): Observable<Models.Result> {
    let idServiceRequestAirportToAirportParam: string = idServiceRequestAirportToAirport != null && idServiceRequestAirportToAirport != undefined ? encodeURIComponent('' + idServiceRequestAirportToAirport) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/Delete?idServiceRequestAirportToAirport=${idServiceRequestAirportToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToAirport_TransportList(idServiceRequestAirportToAirport?: number): Observable<Models.ServiceRequestAirportToAirportTransportOrderItemListResponse> {
    let idServiceRequestAirportToAirportParam: string = idServiceRequestAirportToAirport != null && idServiceRequestAirportToAirport != undefined ? encodeURIComponent('' + idServiceRequestAirportToAirport) : '';
    return this._http.get<Models.ServiceRequestAirportToAirportTransportOrderItemListResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToAirport/TransportList?idServiceRequestAirportToAirport=${idServiceRequestAirportToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_Create(request: Models.ServiceRequestAirportToHotelCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_Read(idServiceRequestAirportToHotel?: number): Observable<Models.ServiceRequestAirportToHotelReadResponse> {
    let idServiceRequestAirportToHotelParam: string = idServiceRequestAirportToHotel != null && idServiceRequestAirportToHotel != undefined ? encodeURIComponent('' + idServiceRequestAirportToHotel) : '';
    return this._http.get<Models.ServiceRequestAirportToHotelReadResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/Read?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_List(idTicket?: number): Observable<Models.ServiceRequestAirportToHotelListResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.ServiceRequestAirportToHotelListResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_Update(request: Models.ServiceRequestAirportToHotelUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_Delete(idServiceRequestAirportToHotel?: number): Observable<Models.Result> {
    let idServiceRequestAirportToHotelParam: string = idServiceRequestAirportToHotel != null && idServiceRequestAirportToHotel != undefined ? encodeURIComponent('' + idServiceRequestAirportToHotel) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/Delete?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestAirportToHotel_TransportList(idServiceRequestAirportToHotel?: number): Observable<Models.ServiceRequestAirportToHotelTransportOrderItemQueryResponse> {
    let idServiceRequestAirportToHotelParam: string = idServiceRequestAirportToHotel != null && idServiceRequestAirportToHotel != undefined ? encodeURIComponent('' + idServiceRequestAirportToHotel) : '';
    return this._http.get<Models.ServiceRequestAirportToHotelTransportOrderItemQueryResponse>(`${this._baseUrl}/api/v1/ServiceRequestAirportToHotel/TransportList?idServiceRequestAirportToHotel=${idServiceRequestAirportToHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_Create(request: Models.ServiceRequestHotelToAirportCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_Read(idServiceRequestHotelToAirport?: number): Observable<Models.ServiceRequestHotelToAirportReadResponse> {
    let idServiceRequestHotelToAirportParam: string = idServiceRequestHotelToAirport != null && idServiceRequestHotelToAirport != undefined ? encodeURIComponent('' + idServiceRequestHotelToAirport) : '';
    return this._http.get<Models.ServiceRequestHotelToAirportReadResponse>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/Read?idServiceRequestHotelToAirport=${idServiceRequestHotelToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_List(idTicket: number): Observable<Models.ServiceRequestHotelToAirportListResponse> {
    let idTicketParam: string = encodeURIComponent('' + idTicket);
    return this._http.get<Models.ServiceRequestHotelToAirportListResponse>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_Update(request: Models.ServiceRequestHotelToAirportUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_Delete(idServiceRequestHotelToAirport?: number): Observable<Models.Result> {
    let idServiceRequestHotelToAirportParam: string = idServiceRequestHotelToAirport != null && idServiceRequestHotelToAirport != undefined ? encodeURIComponent('' + idServiceRequestHotelToAirport) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/Delete?idServiceRequestHotelToAirport=${idServiceRequestHotelToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestHotelToAirport_TransportList(idServiceRequestHotelToAirport?: number): Observable<Models.ServiceRequestHotelToAirportTransportOrderItemQueryResponse> {
    let idServiceRequestHotelToAirportParam: string = idServiceRequestHotelToAirport != null && idServiceRequestHotelToAirport != undefined ? encodeURIComponent('' + idServiceRequestHotelToAirport) : '';
    return this._http.get<Models.ServiceRequestHotelToAirportTransportOrderItemQueryResponse>(`${this._baseUrl}/api/v1/ServiceRequestHotelToAirport/TransportList?idServiceRequestHotelToAirport=${idServiceRequestHotelToAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Create(request: Models.ServiceRequestSnackCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Read(idServiceRequestSnack?: number): Observable<Models.ServiceRequestSnackResponse> {
    let idServiceRequestSnackParam: string = idServiceRequestSnack != null && idServiceRequestSnack != undefined ? encodeURIComponent('' + idServiceRequestSnack) : '';
    return this._http.get<Models.ServiceRequestSnackResponse>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Read?idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_List(idTicket?: number): Observable<Models.ServiceRequestSnackListQueryResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.ServiceRequestSnackListQueryResponse>(`${this._baseUrl}/api/v1/ServiceRequestSnack/List?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Update(request: Models.ServiceRequestSnackUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Delete(idServiceRequestSnack?: number): Observable<Models.Result> {
    let idServiceRequestSnackParam: string = idServiceRequestSnack != null && idServiceRequestSnack != undefined ? encodeURIComponent('' + idServiceRequestSnack) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Delete?idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_Undo(idServiceRequestSnack?: number): Observable<Models.Result> {
    let idServiceRequestSnackParam: string = idServiceRequestSnack != null && idServiceRequestSnack != undefined ? encodeURIComponent('' + idServiceRequestSnack) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/ServiceRequestSnack/Undo?idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceRequestSnack_VoucherSnackActivationList(idServiceRequestSnack: number): Observable<Models.ServiceRequestSnackVoucherSnackActivationListQueryResponse> {
    let idServiceRequestSnackParam: string = encodeURIComponent('' + idServiceRequestSnack);
    return this._http.get<Models.ServiceRequestSnackVoucherSnackActivationListQueryResponse>(`${this._baseUrl}/api/v1/ServiceRequestSnack/VoucherSnackActivationList?idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public ServiceSnackBoardingPassScan_BoardingPassString(resultString?: string, idServiceRequestSnack?: number): Observable<number> {
    let resultStringParam: string = resultString != null && resultString != undefined ? encodeURIComponent('' + resultString) : '';
    let idServiceRequestSnackParam: string = idServiceRequestSnack != null && idServiceRequestSnack != undefined ? encodeURIComponent('' + idServiceRequestSnack) : '';
    return this._http.post<number>(`${this._baseUrl}/api/v1/ServiceSnackBoardingPassScan/BoardingPassString?resultString=${resultStringParam}&idServiceRequestSnack=${idServiceRequestSnackParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Test_TestError(): Observable<number> {
    return this._http.post<number>(`${this._baseUrl}/api/v1/Test/TestError`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Test_ReadDate(): Observable<moment.Moment> {
    return this._http.get<moment.Moment>(`${this._baseUrl}/api/v1/Test/ReadDate`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_Read(idTicket?: number): Observable<Models.Ticket> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.Ticket>(`${this._baseUrl}/api/v1/Ticket/Read?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_Details(idTicket?: number): Observable<Models.Ticket> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.Ticket>(`${this._baseUrl}/api/v1/Ticket/Details?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_Create(request: Models.TicketCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Ticket/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_Update(request: Models.TicketUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Ticket/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_List(request: Models.TicketListQuery): Observable<Models.TicketListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.TicketListQueryResponse>(`${this._baseUrl}/api/v1/Ticket/List`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Ticket_UpdateNote(request: Models.TicketUpdateNoteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Ticket/UpdateNote`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TicketFile_ListByIdTicket(idTicket?: number): Observable<Models.TicketFileListByIdTicketQueryResponse> {
    let idTicketParam: string = idTicket != null && idTicket != undefined ? encodeURIComponent('' + idTicket) : '';
    return this._http.get<Models.TicketFileListByIdTicketQueryResponse>(`${this._baseUrl}/api/v1/TicketFile/ListByIdTicket?idTicket=${idTicketParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TicketFile_Create(request: Models.TicketFile_Create): Observable<number> {
    const wrappedRequest = this._handleMultipart(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/TicketFile/Create`, wrappedRequest, httpOptionsMultipart)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TicketFile_Delete(idTicketFile?: number): Observable<Models.Result> {
    let idTicketFileParam: string = idTicketFile != null && idTicketFile != undefined ? encodeURIComponent('' + idTicketFile) : '';
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/TicketFile/Delete?idTicketFile=${idTicketFileParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Transport_List(filter?: string): Observable<Models.TransportCompanyListQueryResponse> {
    let filterParam: string = filter != null && filter != undefined ? encodeURIComponent('' + filter) : '';
    return this._http.get<Models.TransportCompanyListQueryResponse>(`${this._baseUrl}/api/v1/Transport/List?filter=${filterParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Transport_ReadByIdUser(idUser?: number): Observable<Models.TransportCompany> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    return this._http.get<Models.TransportCompany>(`${this._baseUrl}/api/v1/Transport/ReadByIdUser?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Transport_ListUsers(idTransportCompany?: number): Observable<Models.TransportCompanyListUsersQueryResponse> {
    let idTransportCompanyParam: string = idTransportCompany != null && idTransportCompany != undefined ? encodeURIComponent('' + idTransportCompany) : '';
    return this._http.get<Models.TransportCompanyListUsersQueryResponse>(`${this._baseUrl}/api/v1/Transport/ListUsers?idTransportCompany=${idTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Transport_Create(request: Models.TransportCompanyCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/Transport/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Transport_Update(request: Models.TransportCompanyUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Transport/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public Transport_Delete(idTransportCompany?: number): Observable<Models.Result> {
    let idTransportCompanyParam: string = idTransportCompany != null && idTransportCompany != undefined ? encodeURIComponent('' + idTransportCompany) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/Transport/Delete?idTransportCompany=${idTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_List(idTransportCompany: number, toConfirm: boolean, done: boolean): Observable<Models.TransportOrderListQueryResponse> {
    let idTransportCompanyParam: string = encodeURIComponent('' + idTransportCompany);
    let toConfirmParam: string = encodeURIComponent('' + toConfirm);
    let doneParam: string = encodeURIComponent('' + done);
    return this._http.get<Models.TransportOrderListQueryResponse>(`${this._baseUrl}/api/v1/TransportOrder/List?idTransportCompany=${idTransportCompanyParam}&toConfirm=${toConfirmParam}&done=${doneParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_ListByServiceRequest(idServiceRequest: number, serviceRequestType: Models.ServiceRequestTypeEnum): Observable<Models.TransportOrderListByServiceRequestQueryResponse> {
    let idServiceRequestParam: string = encodeURIComponent('' + idServiceRequest);
    let serviceRequestTypeParam: string = encodeURIComponent('' + serviceRequestType);
    return this._http.get<Models.TransportOrderListByServiceRequestQueryResponse>(`${this._baseUrl}/api/v1/TransportOrder/ListByServiceRequest?idServiceRequest=${idServiceRequestParam}&serviceRequestType=${serviceRequestTypeParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_Read(idTransportOrder?: number): Observable<Models.TransportOrderResponse> {
    let idTransportOrderParam: string = idTransportOrder != null && idTransportOrder != undefined ? encodeURIComponent('' + idTransportOrder) : '';
    return this._http.get<Models.TransportOrderResponse>(`${this._baseUrl}/api/v1/TransportOrder/Read?idTransportOrder=${idTransportOrderParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrder_Update(request: Models.TransportOrderUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<Models.Result>(`${this._baseUrl}/api/v1/TransportOrder/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrderItem_Create(request: Models.TransportOrderItemCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/TransportOrderItem/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrderItem_Update(request: Models.TransportOrderItemUpdateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.put<number>(`${this._baseUrl}/api/v1/TransportOrderItem/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public TransportOrderItem_Delete(idTransportOrderItem?: number): Observable<Models.Result> {
    let idTransportOrderItemParam: string = idTransportOrderItem != null && idTransportOrderItem != undefined ? encodeURIComponent('' + idTransportOrderItem) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/TransportOrderItem/Delete?idTransportOrderItem=${idTransportOrderItemParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Create(request: Models.UserCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/User/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_UpdateLanguageCulture(idLanguageCulture?: number): Observable<Models.Result> {
    let idLanguageCultureParam: string = idLanguageCulture != null && idLanguageCulture != undefined ? encodeURIComponent('' + idLanguageCulture) : '';
    return this._http.get<Models.Result>(`${this._baseUrl}/api/v1/User/UpdateLanguageCulture?idLanguageCulture=${idLanguageCultureParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ReadUserInfo(): Observable<Models.UserReadUserInfoQueryResponse> {
    return this._http.get<Models.UserReadUserInfoQueryResponse>(`${this._baseUrl}/api/v1/User/ReadUserInfo`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_GenerateResetPasswordCode(request: Models.UserGenerateResetPasswordCodeCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/GenerateResetPasswordCode`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_VerifyResetPasswordCode(request: Models.UserVerifyResetPasswordCodeCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/VerifyResetPasswordCode`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ResetPassword(request: Models.UserResetPasswordCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/ResetPassword`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Update(request: Models.UserUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ReadUser(idUser?: number): Observable<Models.UserReadQueryResponse> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    return this._http.post<Models.UserReadQueryResponse>(`${this._baseUrl}/api/v1/User/ReadUser?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_Delete(idUser?: number): Observable<Models.Result> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/User/Delete?idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ListUsersNotInAirport(idAirlineCompany?: number, idAirport?: number): Observable<Models.UserListNotInAirportQueryResponse> {
    let idAirlineCompanyParam: string = idAirlineCompany != null && idAirlineCompany != undefined ? encodeURIComponent('' + idAirlineCompany) : '';
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    return this._http.get<Models.UserListNotInAirportQueryResponse>(`${this._baseUrl}/api/v1/User/ListUsersNotInAirport?idAirlineCompany=${idAirlineCompanyParam}&idAirport=${idAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ListUsersInAirport(idAirlineCompany: number, idAirport: number): Observable<Models.UserListInAirportQueryResponse> {
    let idAirlineCompanyParam: string = encodeURIComponent('' + idAirlineCompany);
    let idAirportParam: string = encodeURIComponent('' + idAirport);
    return this._http.get<Models.UserListInAirportQueryResponse>(`${this._baseUrl}/api/v1/User/ListUsersInAirport?idAirlineCompany=${idAirlineCompanyParam}&idAirport=${idAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_CreateUserInTransportCompany(idUser?: number, idTransportCompany?: number): Observable<string> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    let idTransportCompanyParam: string = idTransportCompany != null && idTransportCompany != undefined ? encodeURIComponent('' + idTransportCompany) : '';
    return this._http.post<string>(`${this._baseUrl}/api/v1/User/CreateUserInTransportCompany?idUser=${idUserParam}&idTransportCompany=${idTransportCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_CreateUserInAirport(idUser?: number, idAirport?: number): Observable<string> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    return this._http.post<string>(`${this._baseUrl}/api/v1/User/CreateUserInAirport?idUser=${idUserParam}&idAirport=${idAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_DeleteUserInAirport(idUser: number, idAirport: number): Observable<Models.Result> {
    let idUserParam: string = encodeURIComponent('' + idUser);
    let idAirportParam: string = encodeURIComponent('' + idAirport);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/User/DeleteUserInAirport?idUser=${idUserParam}&idAirport=${idAirportParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_CreateUserInFoodServiceCompany(idUser?: number, idFoodServiceCompany?: number): Observable<string> {
    let idUserParam: string = idUser != null && idUser != undefined ? encodeURIComponent('' + idUser) : '';
    let idFoodServiceCompanyParam: string = idFoodServiceCompany != null && idFoodServiceCompany != undefined ? encodeURIComponent('' + idFoodServiceCompany) : '';
    return this._http.post<string>(`${this._baseUrl}/api/v1/User/CreateUserInFoodServiceCompany?idUser=${idUserParam}&idFoodServiceCompany=${idFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_DeleteUserInFoodServiceCompany(idUser: number, idFoodServiceCompany: number): Observable<Models.Result> {
    let idUserParam: string = encodeURIComponent('' + idUser);
    let idFoodServiceCompanyParam: string = encodeURIComponent('' + idFoodServiceCompany);
    return this._http.delete<Models.Result>(`${this._baseUrl}/api/v1/User/DeleteUserInFoodServiceCompany?idUser=${idUserParam}&idFoodServiceCompany=${idFoodServiceCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_ListUsersInFoodServiceCompany(idFoodServiceCompany: number, idUser: number): Observable<Models.FoodServiceCompanyListUsersQueryResponse> {
    let idFoodServiceCompanyParam: string = encodeURIComponent('' + idFoodServiceCompany);
    let idUserParam: string = encodeURIComponent('' + idUser);
    return this._http.get<Models.FoodServiceCompanyListUsersQueryResponse>(`${this._baseUrl}/api/v1/User/ListUsersInFoodServiceCompany?idFoodServiceCompany=${idFoodServiceCompanyParam}&idUser=${idUserParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public User_List(request: Models.UserListQuery): Observable<Models.UserListQueryResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.UserListQueryResponse>(`${this._baseUrl}/api/v1/User/List`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_ActivatedList(referenceDate: moment.Moment): Observable<Models.ActivatedVoucherSnackReadQueryResponse> {
    let referenceDateParam: string = encodeURIComponent(this._momentToString(referenceDate));
    return this._http.get<Models.ActivatedVoucherSnackReadQueryResponse>(`${this._baseUrl}/api/v1/VoucherSnack/ActivatedList?referenceDate=${referenceDateParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_List(idAirlineCompany?: number): Observable<Models.VoucherSnackListResponse> {
    let idAirlineCompanyParam: string = idAirlineCompany != null && idAirlineCompany != undefined ? encodeURIComponent('' + idAirlineCompany) : '';
    return this._http.get<Models.VoucherSnackListResponse>(`${this._baseUrl}/api/v1/VoucherSnack/List?idAirlineCompany=${idAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_SnackTypeList(idAirlineCompany?: number): Observable<Models.SnackTypeListResponse> {
    let idAirlineCompanyParam: string = idAirlineCompany != null && idAirlineCompany != undefined ? encodeURIComponent('' + idAirlineCompany) : '';
    return this._http.get<Models.SnackTypeListResponse>(`${this._baseUrl}/api/v1/VoucherSnack/SnackTypeList?idAirlineCompany=${idAirlineCompanyParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_Create(request: Models.VoucherSnackCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/VoucherSnack/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_Read(idVoucherSnackRequestEmission?: number): Observable<Models.VoucherSnackResponse> {
    let idVoucherSnackRequestEmissionParam: string = idVoucherSnackRequestEmission != null && idVoucherSnackRequestEmission != undefined ? encodeURIComponent('' + idVoucherSnackRequestEmission) : '';
    return this._http.get<Models.VoucherSnackResponse>(`${this._baseUrl}/api/v1/VoucherSnack/Read?idVoucherSnackRequestEmission=${idVoucherSnackRequestEmissionParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_ReadByIdSnackType(idSnackType?: number): Observable<Models.SnackType> {
    let idSnackTypeParam: string = idSnackType != null && idSnackType != undefined ? encodeURIComponent('' + idSnackType) : '';
    return this._http.get<Models.SnackType>(`${this._baseUrl}/api/v1/VoucherSnack/ReadByIdSnackType?idSnackType=${idSnackTypeParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_Activate(request: Models.VoucherSnackActivateCommand): Observable<Models.VoucherSnackActivateCommandResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.VoucherSnackActivateCommandResponse>(`${this._baseUrl}/api/v1/VoucherSnack/Activate`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public VoucherSnack_ActivateWithBoardingPass(request: Models.VoucherSnackActivateWithBoardingPassCommand): Observable<Models.VoucherSnackActivateWithBoardingPassCommandResponse> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.VoucherSnackActivateWithBoardingPassCommandResponse>(`${this._baseUrl}/api/v1/VoucherSnack/ActivateWithBoardingPass`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItem_List(onlyOpen?: boolean): Observable<Models.WorkItemListQueryResponse> {
    let onlyOpenParam: string = onlyOpen != null && onlyOpen != undefined ? encodeURIComponent('' + onlyOpen) : '';
    return this._http.get<Models.WorkItemListQueryResponse>(`${this._baseUrl}/api/v1/WorkItem/List?onlyOpen=${onlyOpenParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItem_GetHotelsByAirport(idAirport?: number, idWorkItem?: number): Observable<Models.GetHotelsByAirportQueryResponse> {
    let idAirportParam: string = idAirport != null && idAirport != undefined ? encodeURIComponent('' + idAirport) : '';
    let idWorkItemParam: string = idWorkItem != null && idWorkItem != undefined ? encodeURIComponent('' + idWorkItem) : '';
    return this._http.get<Models.GetHotelsByAirportQueryResponse>(`${this._baseUrl}/api/v1/WorkItem/GetHotelsByAirport?idAirport=${idAirportParam}&idWorkItem=${idWorkItemParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItem_Read(idWorkItem?: number): Observable<Models.WorkItem> {
    let idWorkItemParam: string = idWorkItem != null && idWorkItem != undefined ? encodeURIComponent('' + idWorkItem) : '';
    return this._http.get<Models.WorkItem>(`${this._baseUrl}/api/v1/WorkItem/Read?idWorkItem=${idWorkItemParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItem_Create(request: Models.WorkItemCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/WorkItem/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItem_Update(request: Models.WorkItemUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/WorkItem/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItem_Delete(request: Models.WorkItemDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/WorkItem/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItemsHotel_Read(idWorkItem?: number): Observable<Models.WorkItemsHotel> {
    let idWorkItemParam: string = idWorkItem != null && idWorkItem != undefined ? encodeURIComponent('' + idWorkItem) : '';
    return this._http.get<Models.WorkItemsHotel>(`${this._baseUrl}/api/v1/WorkItemsHotel/Read?idWorkItem=${idWorkItemParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItemsHotel_WorkItemsHotelReadByHotel(idWorkItem?: number, idHotel?: number): Observable<Models.WorkItemsHotel> {
    let idWorkItemParam: string = idWorkItem != null && idWorkItem != undefined ? encodeURIComponent('' + idWorkItem) : '';
    let idHotelParam: string = idHotel != null && idHotel != undefined ? encodeURIComponent('' + idHotel) : '';
    return this._http.get<Models.WorkItemsHotel>(`${this._baseUrl}/api/v1/WorkItemsHotel/WorkItemsHotelReadByHotel?idWorkItem=${idWorkItemParam}&idHotel=${idHotelParam}`, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItemsHotel_Create(request: Models.WorkItemsHotelCreateCommand): Observable<number> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<number>(`${this._baseUrl}/api/v1/WorkItemsHotel/Create`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItemsHotel_Update(request: Models.WorkItemsHotelUpdateCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/WorkItemsHotel/Update`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

  public WorkItemsHotel_Delete(request: Models.WorkItemsHotelDeleteCommand): Observable<Models.Result> {
    const wrappedRequest = this._handleRequest(request);
	  return this._http.post<Models.Result>(`${this._baseUrl}/api/v1/WorkItemsHotel/Delete`, wrappedRequest, httpOptions)
      .pipe(
        map(x => this._handleResponse(x)),
        catchError((err, obs) => this._handleError(err, <Observable<any>>obs))
      );
  }

}
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BedBankCityPageComponent } from './pages/bed-bank-city-page/bed-bank-city-page.component';
import { BedBankHotelPageComponent } from './pages/bed-bank-hotel-page/bed-bank-hotel-page.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'list' },
  { path: 'list', component: BedBankCityPageComponent },
  { path: ':idAirport/list', component: BedBankHotelPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BedBankRoutingModule { }

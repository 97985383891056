<div role="group" class="flex flex-row w-full" [formGroup]="formGroup" (focusin)="onFocusIn($event)"
  (focusout)="onFocusOut($event)">
  <div class="w-2/4 sm:pr-2" [ngClass]="{'w-full' : onlyDate}">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label translate> {{ labelDate }} </mat-label>
      <input matInput formControlName="date" [matDatepicker]="picker">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="w-1/4 sm:pr-2" *ngIf="!onlyDate">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label translate>{{ labelHour }}</mat-label>
      <mat-select formControlName="hours">
        <mat-option *ngFor="let orario of hours" [value]="orario">
          {{ orario }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="w-1/4 sm:pr-2" *ngIf="!onlyDate">
    <mat-form-field appearance="outline" class="w-full">
      <mat-label translate>{{ labelMinutes }}</mat-label>
      <mat-select formControlName="minutes">
        <mat-option *ngFor="let minuto of minutes" [value]="minuto">
          {{ minuto }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>

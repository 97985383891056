import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiAutogeneratedService } from '../autogenerated/api.autogenerated';
import { environment } from 'src/environments/environment';
import { ResponseError } from '../models/response-error';
import { JwtResponseError } from '@devlearning/jwt-auth';
import { throwError } from 'rxjs';
import * as moment from 'moment-timezone';
import { DialogMessageService } from './dialog-message.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService extends ApiAutogeneratedService {

  private _dateTimeFormat = /^((19|20)[0-9][0-9])[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[T]([01]?[0-9]|[2][0-3])[:]([0-5][0-9])[:]([0-5][0-9])[.]([0-9][0-9][0-9])([+|-]([01][0-9]|[2][0-3])[:]([0-5][0-9])){0,1}$/;
  private _timeFormat = /^([01]?[0-9]|[2][0-3])[:]([0-5][0-9])[:]([0-5][0-9])[.]([0-9][0-9][0-9])$/;
  private _ianaName: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
  private _dateTimeFormatString = "YYYY-MM-DDTHH:mm:ss.SSSZ";
  private _timeFormatString = "HH:mm:ss.SSS";

  constructor(
    public override _http: HttpClient,
    private readonly _dialogMessage: DialogMessageService,
  ) {
    super(_http, environment.BASE_URL);
  }

  protected override _momentToString(moment: moment.Moment) {
    return (<moment.Moment>moment).format(this._dateTimeFormatString);
  }

  protected override _handleRequest<T>(request: T) {
    if (request === null || request === undefined) {
      return request;
    }
    if (typeof request !== 'object') {
      return request;
    }

    var clonedRequest = { ...request };

    for (const key of Object.keys(clonedRequest)) {
      const value = (<any>clonedRequest)[key];
      if (moment.isMoment(value)) {
        (<any>clonedRequest)[key] = this._momentToString(value);
      } else if (typeof value === 'object') {
        this._handleRequest(value);
      }
    }

    return clonedRequest;
  }

  protected override _handleMultipart<T>(request: T): FormData {
    const formData = new FormData();
    if (request === null || request === undefined) {
      return formData;
    }

    for (const key of Object.keys(request)) {
      const value = (<any>request)[key];
      if (value instanceof File) {
        formData.append(key, value, value.name);
      } else {
        formData.append(key, value.toString());
      }
    }

    return formData;
  }

  protected override _handleResponse<T>(response: T) {
    if (response === null || response === undefined) {
      return response;
    }
    if (typeof response !== 'object') {
      return response;
    }

    for (const key of Object.keys(response)) {
      const value = (<any>response)[key];
      if (this._isDateString(value)) {
        (<any>response)[key] = moment.tz(value, this._dateTimeFormatString, this._ianaName);
      } else if (this._isTimeString(value)) {
        (<any>response)[key] = moment.tz(value, this._timeFormatString, this._ianaName);
      } else if (typeof value === 'object') {
        this._handleResponse(value);
      }
    }

    return response;
  }

  protected override _handleError(error: any, obs: any) {
    let responseError = new ResponseError();
    if (error.error instanceof Error) {
      console.error('Api - an error occurred:', error.error.message);
      responseError.message = error.error.message;
    } else if (error instanceof JwtResponseError) {
      responseError.message = error.message;
      if (error.isUnhautorized) {
        responseError.message = 'Expired login'; //this._translate.instant('common.expiredLogin');
      } else {
        responseError.stacktrace = error.detailedMessage;
      }
    } else if (error instanceof HttpErrorResponse) {
      responseError = ResponseError.CreateFromHttpErrorResponse(error);
    }

    console.error(`Api - error code ${error.status} message ${responseError.message} ${responseError.stacktrace}`);
    this._dialogMessage.showResponseError(responseError)
    return throwError(() => responseError);
  }

  private _isDateString(value: string) {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return this._dateTimeFormat.test(value);
    }
    return false;
  }

  private _isTimeString(value: string) {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return this._timeFormat.test(value);
    }
    return false;
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { BedBankAirportListQuery, BedBankAirportListQueryResponse, BedBankAirportResponse } from 'src/app/core/autogenerated/model.autogenerated';
import { TailwindBreakpoint } from 'src/app/core/models/tailwind-breakpoint';
import { ApiService } from 'src/app/core/services/api.service';
import { ViewBreakpointsService } from 'src/app/core/services/view-breakpoints.service';
import { MomentFormatType } from 'src/app/sharedmodules/moment-format/moment-format.pipe';

@Component({
  selector: 'app-bed-bank-city-list',
  templateUrl: './bed-bank-city-list.component.html',
  styleUrls: ['./bed-bank-city-list.component.css']
})
export class BedBankAirportListComponent implements OnInit, OnDestroy {
  private _subscription?: Subscription;
  private _langSub?: Subscription;

  /**
   * Pagination
   */
  public PAGESIZE = 10; //50;
  public pageIndex: number = 0;
  public length: number = 0;

  // Filters for the query
  public airportFilter?: string;
  public dateFilter?: moment.Moment;

  public todayMinusOneDate?: moment.Moment;
  public todayDate?: moment.Moment;
  public todayPlusOneDate?: moment.Moment;
  public todayPlusTwoDate?: moment.Moment;

  // Days displayed
  public days?: moment.Moment[] = [];
  // Data
  public bedBankAirportList?: BedBankAirportResponse[];
  // Page loading control
  public loading: boolean = false;
  public errata?: boolean;

  public MomentFormatType = MomentFormatType;

  // Table columns
  public displayedColumns = [
    'city',
    'today-1',
    'today',
    'today+1',
    'today+2'
  ];

  // Tailwind mobile
  public tailwindBreakpoint$: Observable<TailwindBreakpoint | null>;
  public TailwindBreakpointEnum = TailwindBreakpoint;

  constructor(
    private readonly _api: ApiService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _viewBreakpoints: ViewBreakpointsService,
    private readonly _translate: TranslateService
  ) {
    this.tailwindBreakpoint$ = _viewBreakpoints.tailwindBreakpoint$;
    // Refresh of the filter: the dates need to update their format upon language change
    this._langSub = this._translate.onLangChange.subscribe(x => {
      this.calculateDates();
    });
    // Calculate the dates
    this.calculateDates();
  }

  private calculateDates() {
    this.dateFilter = moment();
    this.todayMinusOneDate = moment().subtract(1, 'days');
    this.todayDate = moment();
    this.todayPlusOneDate = moment().add(1, 'days');
    this.todayPlusTwoDate = moment().add(2, 'days');
  }

  ngOnInit(): void {
    this.reload();
  }

  public reload() {
    this.loading = true;

    this._subscription?.unsubscribe();

    // Check if I don't have filters
    const request = this.createRequest();

    this._subscription = this._api.BedBank_BedBankAirportList(request)
      .subscribe({
        next: (x: BedBankAirportListQueryResponse) => {
          if (x) {
            // Calcolo la paginazione
            this.length = x.items?.length ?? 0;
            this.bedBankAirportList = x?.items?.filter((x: BedBankAirportResponse, index: number) => {
              return (
                index >= this.pageIndex * this.PAGESIZE &&
                index < (this.pageIndex + 1) * this.PAGESIZE
              );
            }) ?? [];
          }
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
        },
      });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
    this._langSub?.unsubscribe();
  }

  public paginazione(paginazione: PageEvent) {
    this.pageIndex = paginazione.pageIndex;
    this.reload();
  }

  createRequest(): BedBankAirportListQuery {
    const queryFilter = new BedBankAirportListQuery();
    queryFilter.airport = this.airportFilter;
    queryFilter.date = this.dateFilter;
    return queryFilter;
  }

  filterSearch() {
    this.pageIndex = 0;
    this.reload();
  }

  onClearFilter() {
    this.airportFilter = undefined;
    this.pageIndex = 0;
    this.reload();
  }

  navigate(bedBankAirport: BedBankAirportResponse) {
    this._router.navigate([bedBankAirport.idAirport, 'list'], { relativeTo: this._activatedRoute.parent });
  }
}

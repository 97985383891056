export enum TailwindBreakpoint {
    /**
     * max-width: 640px
     */
    XS = 1,

    /**
     * min-width: 640px
     */
    SM = 2,

    /**
     * min-width: 768px
     */
    MD = 3,

    /**
     * min-width: 1024px
     */
    //@media (min-width: 1024px)
    LG = 4,

    /**
     * min-width: 1280px
     */
    XL = 5,
    
    /**
     * min-width: 1536px
     */
    XXL = 6
}
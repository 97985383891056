<!--breadcrumb-->
<div class="w-full flex flex-row items-center pb-4">
  <div class="flex flex-col">
    <button mat-button color="primary" class="w-48" disabled>
      <span class="text-xl text-black"><b>Layover Bed Bank</b></span>
    </button>
  </div>
</div>

<!--Component-->
<div *ngIf="loading">
  <app-loader-spinner></app-loader-spinner>
</div>

<ng-container *ngIf="tailwindBreakpoint$ | async; let viewBreakpoint">
  <ng-container *ngIf="!loading">

    <ng-container *ngIf="viewBreakpoint >= TailwindBreakpointEnum.MD">
      <!--Table Cities-->
      <table mat-table [dataSource]="bedBankAirportList!">
        <!--City-->
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <!--Table head City-->
              <div class="p-3 rounded-xl text-center" style="background-color: #3E6299;">
                <span class="text-white text-xl">
                  {{ 'BED_BANK.AIRPORT' | translate }}
                </span>
              </div>
              <!--Filter by City-->
              <div class="h-16">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label translate>BED_BANK.AIRPORT_FILTER</mat-label>
                  <input type="text" [(ngModel)]="airportFilter" name="airportFilter" matInput
                    (keydown.enter)="filterSearch()">

                  <button mat-icon-button matSuffix aria-label="Menu" (click)="filterSearch()">
                    <mat-icon>search</mat-icon>
                  </button>
                  <button mat-icon-button matSuffix aria-label="Menu" (click)="onClearFilter()">
                    <mat-icon>clear</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>

          </th>
          <td mat-cell *matCellDef="let element">
            <button mat-button color="primary" class="w-full text-left" style="text-align: left;"
              (click)="navigate(element)">
              {{ element.iataCode + ' - ' + element.airport }}
            </button>
          </td>
        </ng-container>

        <!-- Today -1 -->
        <ng-container matColumnDef="today-1">
          <th mat-header-cell *matHeaderCellDef translate>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <app-bed-bank-city-th
                [title]="todayMinusOneDate | momentFormat: MomentFormatType.DATE"></app-bed-bank-city-th>
            </div>
          </th>

          <td mat-cell *matCellDef="let element">
            <!--Values-->
            <div class="mr-5">
              <app-bed-bank-city-td
                [bedBankAirportRoom]="element.bedBankAirportRoomsTodayMinusOne"></app-bed-bank-city-td>
            </div>
          </td>
        </ng-container>

        <!-- Today -->
        <ng-container matColumnDef="today">
          <th mat-header-cell *matHeaderCellDef translate>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <app-bed-bank-city-th [today]="true" 
                [title]="todayDate | momentFormat: MomentFormatType.DATE"></app-bed-bank-city-th>
            </div>
          </th>

          <td mat-cell *matCellDef="let element">
            <!--Values-->
            <div class="mr-5">
              <app-bed-bank-city-td [today]="true" [bedBankAirportRoom]="element.bedBankAirportRoomsToday"></app-bed-bank-city-td>
            </div>
          </td>
        </ng-container>

        <!-- Today +1 -->
        <ng-container matColumnDef="today+1">
          <th mat-header-cell *matHeaderCellDef translate>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <app-bed-bank-city-th
                [title]="todayPlusOneDate | momentFormat: MomentFormatType.DATE"></app-bed-bank-city-th>
            </div>
          </th>

          <td mat-cell *matCellDef="let element">

            <!--Values-->
            <div class="mr-5">
              <app-bed-bank-city-td
                [bedBankAirportRoom]="element.bedBankAirportRoomsTodayPlusOne"></app-bed-bank-city-td>
            </div>

          </td>
        </ng-container>

        <!-- Today +2 -->
        <ng-container matColumnDef="today+2">
          <th mat-header-cell *matHeaderCellDef translate>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <app-bed-bank-city-th
                [title]="todayPlusTwoDate | momentFormat: MomentFormatType.DATE"></app-bed-bank-city-th>
            </div>
          </th>

          <td mat-cell *matCellDef="let element">

            <!--Values-->
            <div class="mr-5">
              <app-bed-bank-city-td
                [bedBankAirportRoom]="element.bedBankAirportRoomsTodayPlusTwo"></app-bed-bank-city-td>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.errata]="row.errata == true"></tr>
      </table>
    </ng-container>
    <!-- <ng-container *ngIf="viewBreakpoint < TailwindBreakpointEnum.MD">
          <div class="w-full pt-2">
              <div *ngFor="let element of bedBankCityList">

              </div>
          </div>
      </ng-container> -->

    <!--PAGINAZIONE-->
    <mat-paginator (page)="paginazione($event)" [length]="length" [pageSize]="PAGESIZE" [hidePageSize]="true"
      [pageIndex]="pageIndex" [showFirstLastButtons]="true" aria-label="Select page">
    </mat-paginator>
  </ng-container>
</ng-container>

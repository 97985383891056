import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, Inject, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, NgControl, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorStateMatcher, _AbstractConstructor, _Constructor } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_FORM_FIELD, MatFormField, MatFormFieldControl } from '@angular/material/form-field';
import { MatLegacyFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule } from '@angular/material/legacy-select';
import { TranslateModule } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject } from 'rxjs';


interface DateTimeForm {
  date: FormControl<Date | null>;
  hours: FormControl<string | null>;
  minutes: FormControl<string | null>;
}

@Component({
  selector: 'date-time-form',
  templateUrl: './date-time-form.component.html',
  styleUrls: ['./date-time-form.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatLegacyInputModule,
    MatLegacyFormFieldModule,
    MatLegacySelectModule,
    TranslateModule
  ],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DateTimeFormComponent
    },
    // {
    //   provide: NG_VALUE_ACCESSOR,
    //   useExisting: forwardRef(() => DateTimeFormComponent),
    //   multi: true,
    // },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateTimeFormComponent
  implements
  OnInit,
  OnDestroy,
  DoCheck,
  // CanUpdateErrorState,
  ControlValueAccessor,
  MatFormFieldControl<moment.Moment>
{
  static nextId = 0;

  @Input('aria-describedby') userAriaDescribedBy?: string;
  @Input('onlyDate') onlyDate: boolean = false

  @Input('labelDate') labelDate: string = 'TICKET.EDITOR_A_TO_A.DATA';
  @Input('labelHour') labelHour: string = 'TICKET.EDITOR_A_TO_A.ORA';
  @Input('labelMinutes') labelMinutes: string = 'TICKET.EDITOR_A_TO_A.MINUTI';

  @Input()
  get placeholder(): string {
    return this._placeholder!;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.formGroup.disable() : this.formGroup.enable();
    this.stateChanges.next();
  }

  @ViewChild('day') dayInput?: HTMLInputElement;
  @ViewChild('hours') hoursInput?: HTMLInputElement;
  @ViewChild('minutes') minutesInput?: HTMLInputElement;

  private _placeholder?: string;
  private _disabled = false;
  private _required = false;
  private _parentForm: NgForm;
  private _parentFormGroup: FormGroupDirective | null = null;
  private _errorState: boolean = false;
  public stateChanges = new Subject<void>();
  public hours: string[] = [];
  public minutes: string[] = [];
  public formGroup = new FormGroup<DateTimeForm>({
    date: new FormControl(null, this.required ? [Validators.required] : []),
    hours: new FormControl(null, this.required ? [Validators.required] : []),
    minutes: new FormControl(null, this.required ? [Validators.required] : []),
  });
  public focused = false;
  public touched = false;
  public controlType = 'date-time-form';
  public id = `date-time-form-${DateTimeFormComponent.nextId++}`;
  value: moment.Moment | null = null;

  get empty() {
    const {
      value: { date, hours, minutes },
    } = this.formGroup;

    return !date && !hours && !minutes;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState(): boolean {
    return this.formGroup?.invalid && this.touched;
  }
  set errorState(value) {
    this._errorState = value;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private _changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl,
    @Optional() _parentForm: NgForm,
    @Optional() _parentFormGroup: FormGroupDirective,
    _defaultErrorStateMatcher: ErrorStateMatcher
  ) {
    this._parentForm = _parentForm;
    this._parentFormGroup = _parentFormGroup;

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
    this.hours = this.generateArray(23, 0);
    this.minutes = this.generateArray(59, 0);
  }

  // updateErrorState(): void {
  //   throw new Error('Method not implemented.');
  // }

  ngOnInit(): void {
    this.formGroup.statusChanges.subscribe(x => {
      if (this.formGroup.valid) {
        let momentDate = moment(this.formGroup.value.date);

        if (!this.onlyDate) {
          if (this.formGroup.value.hours) {
            if (this.formGroup.value.hours?.startsWith('0')) {
              momentDate.set('hours', parseInt(this.formGroup.value.hours.substring(1)));
            } else {
              momentDate.set('hours', parseInt(this.formGroup.value.hours));
            }
          }

          if (this.formGroup.value.minutes) {
            if (this.formGroup.value.minutes?.startsWith('0')) {
              momentDate.set('minutes', parseInt(this.formGroup.value.minutes.substring(1)));
            } else {
              momentDate.set('minutes', parseInt(this.formGroup.value.minutes));
            }
          }
        } else {
          momentDate.set('hours', 0);
          momentDate.set('minutes', 0);
        }

        this.value = momentDate.isValid() ? momentDate : null
        // console.debug(this.value);
      } else {
        this.value = null;
      }

      this.propagateChange(this.value);
      this._changeDetectorRef.markForCheck();
      this.stateChanges.next(undefined);
    });
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.date-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick() {
    if (this.formGroup.controls.minutes.valid) {
      this._focusMonitor.focusVia(this.minutesInput!, 'program');
    } else if (this.formGroup.controls.hours.valid) {
      this._focusMonitor.focusVia(this.minutesInput!, 'program');
    } else if (this.formGroup.controls.date.valid) {
      this._focusMonitor.focusVia(this.hoursInput!, 'program');
    } else {
      this._focusMonitor.focusVia(this.dayInput!, 'program');
    }
  }

  writeValue(momentDate: moment.Moment | null): void {
    let date = momentDate && momentDate.isValid() ? momentDate.toDate() : null;
    let hours = 0;
    let minutes = 0;
    if (!this.onlyDate) {
      hours = momentDate && momentDate.isValid() ? momentDate.get('hours') : 0;
      minutes = momentDate && momentDate.isValid() ? momentDate.get('minutes') : 0;
    }

    this.formGroup.patchValue({
      date: date,
      hours: hours ? (hours <= 9 ? '0' + hours : '' + hours) : '0',
      minutes: minutes ? (minutes <= 9 ? '0' + minutes : '' + minutes) : '0',
    })

    this._changeDetectorRef.markForCheck();
    this.stateChanges.next(undefined);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private updateErrorState() {
    const parent = this._parentFormGroup || this._parentForm;

    const oldState = this.errorState;
    const newState = (this.ngControl?.invalid || this.formGroup.invalid) && (this.touched || parent.submitted);

    if (oldState !== newState) {
      this.errorState = newState;
      this.stateChanges.next();
    }
  }

  /**
   * Genera un array di numeri dal valore min al valore max
   * @param max valore massimo a cui arrivare
   * @param min valore minimo da cui partire
   * @returns array di numeri da min a max
   */
  private generateArray(max: number, min: number): string[] {
    if (max > 23) {
      const length = Math.floor(((max - min) / 5)) + 1;
      const range = [...Array(length).keys()].map(x => ((x * 5) + min) > 9 ? (x * 5) + min + '' : '0' + ((x * 5) + min));
      return range;
    }
    else {
      return Array.from({ length: max - min + 1 }, (_, i) =>
        min + i > 9 ? '' + (min + i) : '0' + (min + i)
      );
    }
  }

  onTouched = () => { };
  propagateChange = (_: any) => { };
}

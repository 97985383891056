import { Component, Input, OnInit } from '@angular/core';
import { BedBankHotelRoom } from 'src/app/core/autogenerated/model.autogenerated';

@Component({
  selector: 'app-bed-bank-hotel-td',
  templateUrl: './bed-bank-hotel-td.component.html',
  styleUrls: ['./bed-bank-hotel-td.component.css']
})
export class BedBankHotelTdComponent implements OnInit {
  @Input() bedBankHotelRoom?: BedBankHotelRoom;
  @Input() today: boolean = false;


  public singleRooms: number = 0;
  public doubleRooms: number = 0;
  public tripleRooms: number = 0;

  constructor() {
  }

  ngOnInit(): void {
    if (this.bedBankHotelRoom) {
      this.singleRooms = this.bedBankHotelRoom.singleRooms ?? 0;
      this.doubleRooms = this.bedBankHotelRoom.doubleRooms ?? 0;
      this.tripleRooms = this.bedBankHotelRoom.tripleRooms ?? 0;
    }
  }
}

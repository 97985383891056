import { Component } from '@angular/core';

@Component({
  selector: 'app-bed-bank-city-page',
  templateUrl: './bed-bank-city-page.component.html',
  styleUrls: ['./bed-bank-city-page.component.css']
})
export class BedBankCityPageComponent {

}

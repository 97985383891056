import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, forkJoin, of, throwError } from 'rxjs';
import { JwtAuthService } from '@devlearning/jwt-auth'
import { catchError, exhaustMap, filter, map, tap } from 'rxjs/operators';
import { DialogMessageService } from './dialog-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationToken } from '../models/authentication-token';
import { ApiService } from './api.service';
import { Airport, UserReadUserInfoQueryResponse, UserTypeEnum } from '../autogenerated/model.autogenerated';


@Injectable({
  providedIn: 'root',
})
export class UserService {

  private _subscriptionAuth: Subscription | null = null;
  private _idUser: number | null = null;
  private _aeroportoSubject = new BehaviorSubject<Airport | undefined>(undefined);
  private _datiUtenteSubject = new BehaviorSubject<UserReadUserInfoQueryResponse | null>(null);

  public get aeroportoUtente$() { return this._aeroportoSubject.asObservable(); }
  public get datiUtente$() { return this._datiUtenteSubject.asObservable(); }
  public get idUser(): number { return this._idUser!; }
  public get datiUtente(): UserReadUserInfoQueryResponse | null { return this._datiUtenteSubject.value; }
  public get isAdmin() { return this.datiUtente?.idTipoUtente == UserTypeEnum.ADMIN; }

  constructor(
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _api: ApiService,
    private readonly _jwtAuth: JwtAuthService<AuthenticationToken>,
    _dialogMessage: DialogMessageService
  ) {
  }

  public initialize() {
    return this._jwtAuth.init()
      .pipe(
        tap(x => {
          if (x != null && x.idUser != null)
            this._idUser = x.idUser;
        }),
        exhaustMap(x => forkJoin({
          aeroporto: this._initializeAeroportoUtente(),
          datiUtente: this._initializeDatiUtente()
        })),
        tap(x=>{
          this._subscriptionAuth?.unsubscribe();
          this._subscriptionAuth = this._jwtAuth.isLoggedIn$
            .pipe(
              filter(x => !x)
            )
            .subscribe(x => {
              // debugger
              this._idUser = null;
              this._datiUtenteSubject.next(null);
              this._aeroportoSubject.next(undefined);
              // this._router.navigate(['login']);
            });
        })
      )
  }

  public isAuthenticated(): boolean {
    return this._jwtAuth.isLoggedIn;
  }

  public login(username: string, password: string) {
    return this._jwtAuth.token({ username: username, password: password })
      .pipe(
        exhaustMap(x => this.initialize()),
        map(x => this.datiUtente),
        catchError((err, obs) => {
          return throwError(() => new Error(err));
        })
      );
  }

  public logout() {
    this._jwtAuth.logout();
    this._idUser = null;
    this._datiUtenteSubject.next(null);
    this._aeroportoSubject.next(undefined);
    console.debug('%cUSER SERVICE', 'font-size: 40px')
    this._router.navigate(['login']);
  }

  private _initializeAeroportoUtente() {
    if (this._idUser != null) {
      return this._api.Airport_ReadByCurrentUser()
        .pipe(
          tap(x => this._aeroportoSubject.next(x))
        );
    } else {
      return of<Airport | null>(null);
    }
  }

  private _initializeDatiUtente() {
    if (this.idUser) {
      return this._api.User_ReadUserInfo()
        .pipe(
          tap(x => this._datiUtenteSubject.next(x))
        )
    } else {
      return of<UserReadUserInfoQueryResponse | null>(null);
    }
  }
}

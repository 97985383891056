<div class="h-16 flex items-center justify-center">
  <div class="w-full flex flex-row justify-around">
    <!--hotels-->
    <div [ngStyle]="{'background-color': today ? '#D0EAD8' : '#C3D3FD'}" class="w-1/4 items-center flex flex-col rounded-xl text-center m-0.5 p-0.5">
      <span class="text-black text-lg">
        {{ hotels }}
      </span>
    </div>
    <!--rooms-->
    <div [ngStyle]="{'background-color': today ? '#CBE5E3' : '#CFDBFB'}" class="w-1/4 items-center flex flex-col rounded-xl text-center m-0.5 p-0.5">
      <span class="text-black text-lg" [matTooltip]="tooltip">
        {{ rooms }}
      </span>
    </div>
    <!--occupancy-->
    <div [ngStyle]="{'background-color': today ? '#DCEFEE' : '#E1E9FF'}" class="w-1/4 items-center flex flex-col rounded-xl text-center m-0.5 p-0.5">
      <span class="text-black text-lg">
        {{ occupancy }}
      </span>
    </div>
  </div>
</div>

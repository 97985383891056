<!--Table head City-->
<div class="p-3 rounded-xl text-center" [ngStyle]="{'background-color': today ? '#3E9997' : '#3E6299'}">
  <span class="text-white text-xl">
    {{ title }}
  </span>
</div>
<!--Icons-->
<div class="h-16 flex items-center justify-center">

  <div class="w-full flex flex-row align-middle">
    <div class="w-1/3 items-center flex flex-col">
      <mat-icon>business</mat-icon>
    </div>
    <div class="w-1/3 items-center flex flex-col">
      <mat-icon>hotel</mat-icon>
    </div>
    <div class="w-1/3 items-center flex flex-col">
      <mat-icon>person</mat-icon>
    </div>
  </div>

</div>

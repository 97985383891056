<!--breadcrumb-->
<div class="w-full flex flex-row items-center pb-4">
  <div class="flex flex-col">
    <button mat-button color="primary" class="w-48" (click)="navigateToBedBank()">
      <span class="text-xl"><b>Layover Bed Bank</b></span>
    </button>
  </div>
  <div class="flex flex-col">
    <span  class="text-xl pr-4"><b>-</b></span>
  </div>

  <div class="flex flex-col">
    <span class="text-xl"><b>{{airport?.name}}</b></span>
  </div>
</div>

<!--Component-->
<div *ngIf="loading">
  <app-loader-spinner></app-loader-spinner>
</div>

<ng-container *ngIf="tailwindBreakpoint$ | async; let viewBreakpoint">
  <ng-container *ngIf="!loading">

    <ng-container *ngIf="viewBreakpoint >= TailwindBreakpointEnum.MD">
      <!--Table Cities-->
      <table mat-table [dataSource]="bedBankHotelList!">
        <!--Airport-->
        <ng-container matColumnDef="airport">
          <th mat-header-cell *matHeaderCellDef>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <!--Table head Airport-->
              <div class="p-3 rounded-xl text-center" style="background-color: #3E6299;">
                <span class="text-white text-xl">
                  {{ 'BED_BANK.HOTEL' | translate }}
                </span>
              </div>
              <!--Filter by Airport-->
              <div class="h-16">
                <mat-form-field appearance="outline" class="w-full">
                  <mat-label translate>BED_BANK.AIRPORT_FILTER</mat-label>
                  <input type="text" [(ngModel)]="hotelFilter" name="hotelFilter" matInput
                    (keydown.enter)="filterSearch()">

                  <button mat-icon-button matSuffix aria-label="Menu" (click)="filterSearch()">
                    <mat-icon>search</mat-icon>
                  </button>
                  <button mat-icon-button matSuffix aria-label="Menu" (click)="onClearFilter()">
                    <mat-icon>clear</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </div>

          </th>
          <td mat-cell *matCellDef="let element">
            <span class="w-full text-left pl-4">
              {{ element.name }}
            </span>
          </td>
        </ng-container>

        <!-- Today -1 -->
        <ng-container matColumnDef="today-1">
          <th mat-header-cell *matHeaderCellDef translate>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <app-bed-bank-hotel-th
                [title]="todayMinusOneDate | momentFormat: MomentFormatType.DATE"></app-bed-bank-hotel-th>
            </div>
          </th>

          <td mat-cell *matCellDef="let element">
            <!--Values-->
            <div class="mr-5">
              <app-bed-bank-hotel-td
                [bedBankHotelRoom]="element.bedBankHotelRoomsTodayMinusOne"></app-bed-bank-hotel-td>
            </div>
          </td>
        </ng-container>

        <!-- Today -->
        <ng-container matColumnDef="today">
          <th mat-header-cell *matHeaderCellDef translate>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <app-bed-bank-hotel-th [today]="true"
                [title]="todayDate | momentFormat: MomentFormatType.DATE"></app-bed-bank-hotel-th>
            </div>
          </th>

          <td mat-cell *matCellDef="let element">
            <!--Values-->
            <div class="mr-5">
              <app-bed-bank-hotel-td [today]="true" [bedBankHotelRoom]="element.bedBankHotelRoomsToday"></app-bed-bank-hotel-td>
            </div>
          </td>
        </ng-container>

        <!-- Today +1 -->
        <ng-container matColumnDef="today+1">
          <th mat-header-cell *matHeaderCellDef translate>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <app-bed-bank-hotel-th
                [title]="todayPlusOneDate | momentFormat: MomentFormatType.DATE"></app-bed-bank-hotel-th>
            </div>
          </th>

          <td mat-cell *matCellDef="let element">

            <!--Values-->
            <div class="mr-5">
              <app-bed-bank-hotel-td [bedBankHotelRoom]="element.bedBankHotelRoomsTodayPlusOne"></app-bed-bank-hotel-td>
            </div>

          </td>
        </ng-container>

        <!-- Today +2 -->
        <ng-container matColumnDef="today+2">
          <th mat-header-cell *matHeaderCellDef translate>
            <!--Box header-->
            <div class="mt-5 mr-5">
              <app-bed-bank-hotel-th
                [title]="todayPlusTwoDate | momentFormat: MomentFormatType.DATE"></app-bed-bank-hotel-th>
            </div>
          </th>

          <td mat-cell *matCellDef="let element">

            <!--Values-->
            <div class="mr-5">
              <app-bed-bank-hotel-td [bedBankHotelRoom]="element.bedBankHotelRoomsTodayPlusTwo"></app-bed-bank-hotel-td>
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.errata]="row.errata == true"></tr>
      </table>
    </ng-container>
    <!-- <ng-container *ngIf="viewBreakpoint < TailwindBreakpointEnum.MD">
          <div class="w-full pt-2">
              <div *ngFor="let element of bedBankHotelList">

              </div>
          </div>
      </ng-container> -->

    <!--PAGINAZIONE-->
    <mat-paginator (page)="paginazione($event)" [length]="length" [pageSize]="PAGESIZE" [hidePageSize]="true"
      [pageIndex]="pageIndex" [showFirstLastButtons]="true" aria-label="Select page">
    </mat-paginator>
  </ng-container>
</ng-container>

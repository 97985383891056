import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/language.service';

@Pipe({
  name: 'customCurrency',
})
export class CustomCurrencyPipe implements PipeTransform {

  // constructor(
  //   private readonly _language: LanguageService,
  //   private readonly _translate: TranslateService) {

  // }

  transform(
    value: number,
    currencyCode: string = 'EUR',
    withDecimal: boolean = true,
    locale: string = 'it',
  ): string | null {
    let display:
      | 'code'
      | 'symbol'
      | 'symbol-narrow'
      | string
      | boolean = 'symbol';

    let digitsInfo: string = '1.2-2';
    if (!withDecimal) {
      digitsInfo = '1.0-0';
    }

    return formatCurrency(
      value,
      locale,
      getCurrencySymbol(currencyCode, 'wide'),
      currencyCode,
      digitsInfo,
    );
  }
}

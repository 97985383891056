import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BarcodeFormat } from '@zxing/library';
import { ZXingScannerComponent, ZXingScannerModule } from '@zxing/ngx-scanner';
import { Subject, Subscription, debounceTime, distinctUntilChanged, filter, map } from 'rxjs';

@Component({
  selector: 'app-code-scanner',
  templateUrl: './code-scanner.component.html',
  styleUrls: ['./code-scanner.component.css'],
  standalone: true,
  imports: [CommonModule, ZXingScannerModule, MatButtonModule, MatIconModule],
})
export class CodeScannerComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * Prende in input la camera che deve aprire. Se non ne ha, prende di default il primo.
   */
  @Input() public currentDevice?: MediaDeviceInfo;

  /**
  * Event Emitter per gli errori della fotocamera.
  */
  @Output() cameraError: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Event emitter per il risultato della scansione.
   */
  @Output() scanSucceeded: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('scanner', { static: true }) scanner?: ZXingScannerComponent;

  private _scanSubject: Subject<string | undefined> = new Subject<string | undefined>();
  private _subscriptions: Subscription[] = [];
  private _devices: MediaDeviceInfo[] = [];

  /**
   * I formati supportati dallo scanner
   */
  public supportedBarcodeFormats: BarcodeFormat[] = [
    BarcodeFormat.AZTEC,
    BarcodeFormat.PDF_417,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.QR_CODE,
  ];

  constructor() { }

  ngOnInit(): void {
    // Lo scanner chiede il permesso all'utente di accedere alla fotocamera.
    this.scanner
      ?.askForPermission()
      .then((permissionGranted: boolean) => {
        // Se il permesso è garantito posso controllare i device presenti
        if (permissionGranted) {
          // Guardo i device presenti
          this.scanner?.updateVideoInputDevices()
            .then((deviceList: MediaDeviceInfo[]) => {

              if (deviceList.length == 0) {
                this.signalError(ScannerErrorEnum.NO_DEVICE_FOUND);
                return;
              }

              this._devices = deviceList;

              setTimeout(() => {
                // Se non è assegnato un device, prendo il primo device che trovo
                if (this.currentDevice == undefined) {
                  this.currentDevice = deviceList[0];
                }
              }, 1);
            });
        } else {
          console.debug(ScannerErrorEnum.ACCESS_DENIED);
          this.cameraError.emit(ScannerErrorEnum.ACCESS_DENIED);
        }
      })
      .catch((e) => {
        console.debug(e);
      });

    if (this.scanner) {
      const subscr1 = this.scanner.deviceChange.subscribe((x) => {
        console.debug('Current device is', this.currentDevice?.label);
      });
      this._subscriptions.push(subscr1);
    }

    const subscr2 = this._scanSubject
      .pipe(
        filter(x => x != null && x != undefined),
        debounceTime(100),
        distinctUntilChanged(),
        // map(x => ({ key: x, time: Date.now() })),
        // distinctUntilChanged((prev, curr) => (prev.key == curr.key) && ((prev.time + 5000) > curr.time)),
        // map(x => x.key)
      ).subscribe(x => {
        var audio = new Audio();
        audio.src = '../assets/audio/beep_2.mp3';
        audio.load();
        audio.play();
        this.scanSucceeded.emit(x);
      });
    this._subscriptions.push(subscr2);
  }

  ngAfterViewInit(): void { }

  ngOnDestroy(): void {
    this._subscriptions.forEach(x => x.unsubscribe());
  }

  /**
   * Segnala al componente padre quando avviene un errore.
   * @param error l'errore avvenuto.
   */
  private signalError(error: string) {
    this.cameraError.emit(error);
    console.debug(error);
  }

  /**
   * La scansione è terminata.
   * @param event evento della scansione.
   */
  onScanComplete(event: any) {
    console.debug("SCANNER - onScanComplete", event);
  }

  /**
   * La scansione ha avuto successo.
   * @param resultString risultato della scansione
   */
  onScanSuccess(resultString: string) {
    // console.debug("SCANNER - onScanSuccess", event);
    // this.scanSucceeded.emit(resultString);
    this._scanSubject.next(resultString);
  }

  /**
   * Ottiene la lista di devices sul dispositivo.
   * @returns array con i devices, oppure array vuoto.
   */
  public getDevices(): MediaDeviceInfo[] {
    return this._devices ?? [];
  }

  /**
   * Setta la fotocamera corrente in base al suo id.
   * @param deviceId id della fotocamera. Prendi la lista con getDevices.
   */
  public setDeviceById(deviceId: string) {
    const deviceFound = this._devices?.find((x) => x.deviceId == deviceId);

    if (deviceFound === undefined) {
      this.signalError(ScannerErrorEnum.DEVICE_NOT_FOUND);
    } else {
      this.currentDevice = deviceFound;
    }
  }

  /**
   * Setta la fotocamera corrente.
   * @param device la fotocamera da settare come corrente. Prendi la lista con getDevices.
   */
  public setDevice(device: MediaDeviceInfo) {
    // Dobbiamo essere sicuri che trovi il device
    const deviceFound = this._devices?.find(
      (x) => x.deviceId == device.deviceId
    );

    if (device == undefined || deviceFound === undefined) {
      this.signalError(ScannerErrorEnum.DEVICE_NOT_FOUND);
    } else {
      this.currentDevice = deviceFound;
    }
  }

  /**
   * Cambia camera con la prossima in lista
   */
  public nextCamera() {
    let currentDeviceIndex = this._devices?.findIndex(x => x.deviceId == this.currentDevice?.deviceId) ?? -1;
    currentDeviceIndex += 1;

    if (currentDeviceIndex >= this._devices.length) {
      currentDeviceIndex = 0;
    }

    const nextDevice = this._devices[currentDeviceIndex];

    if (nextDevice.deviceId != this.currentDevice?.deviceId) {
      this.setDevice(nextDevice);
    }
  }
}

export enum ScannerErrorEnum {
  DEVICE_NOT_FOUND = 'Device not found',
  ACCESS_DENIED = 'Access denied',
  NO_DEVICE_FOUND = 'No device found',
}
